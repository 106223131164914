import './styles.scss'

import { Title } from '../../components/Elements'
import ShowCase from '../../components/ShowCase'
import { Col, Container, Row } from 'react-bootstrap'

import StarIcon from '../../assets/star.svg'
import {GrFacebookOption, GrTwitter} from 'react-icons/gr'
import {AiOutlineInstagram, AiFillYoutube} from 'react-icons/ai'
import Card from '../../components/CourseCard'

import BgImg from '../../assets/300x440.png'

export default function InstructorDiteils() {
  return (
    <>
        <ShowCase pagination='ABOUT US' height='70vh'>
            <Title line2='FAQ' textAlighCenter={true}/>
        </ShowCase>
        <div className="instructor-diteils">
            <Container>
                <Row>
                    <Col md={3} className='pe-4'>
                        <div className="avatar">
                            <img src={BgImg} alt="instructor avatar" width='300' />
                        </div>
                    </Col>
                    <Col md={9}>
                        <div className="instructor-info">
                            <div>
                                <div className="full-name">Kelly Franklin</div>
                                <span className='grade'>Teaches Art & Design</span>
                            </div>
                            <div>
                                <div className="rating">
                                    <img src={StarIcon} alt="star icon" width='16' />
                                    <img src={StarIcon} alt="star icon" width='16' />
                                    <img src={StarIcon} alt="star icon" width='16' />
                                    <img src={StarIcon} alt="star icon" width='16' />
                                    <img src={StarIcon} alt="star icon" width='16' />
                                    <b>5.00 (2k)</b>
                                </div>
                                <span>Star Rating</span>
                            </div>
                            <div>
                                <div className="socials">
                                    <a href="/#" className='socials__item'>
                                        <GrFacebookOption />
                                    </a>
                                    <a href="/#" className='socials__item'>
                                        <AiOutlineInstagram />
                                    </a>
                                    <a href="/#" className='socials__item'>
                                        <GrTwitter />
                                    </a>
                                    <a href="/#" className='socials__item'>
                                        <AiFillYoutube />
                                    </a>
                                </div>
                                <span>Follow me</span>
                            </div>
                        </div>
                        <hr className="divider" />
                        <div className="about">
                            <div className="about__title">About Me</div>
                            <p>Phasellus luctus ante eu nunc mollis, eget laoreet augue luctus. Proin tincidunt a sapien sed ultrices. Pellentesque faucibus quis augue id iaculis. Aliquam iaculis urna velit, a varius sem interdum et. Etiam eu molestie ipsum. Suspendisse vulputate elit eget volutpat pharetra. Maecenas eget iaculis leo. Suspendisse a tincidunt ante. Mauris semper odio ac felis aliquet, eu elementum lorem faucibus. Donec non tristique nunc. Maecenas tempus luctus arcu. Mauris quam lectus, viverra a tincidunt non, vehicula at metus. Ut mattis quam quis enim vehicula, quis ultricies urna finibus.</p>
                        </div>
                        <hr className="divider" />
                        <div className="courses">
                            <div className="courses__title">My Courses</div>
                            <Row className='cards-wrapper'>
                                <Col lg={4} md={6}>
                                    <Card />
                                </Col>
                                <Col lg={4} md={6}>
                                    <Card />
                                </Col>
                                <Col lg={4} md={6}>
                                    <Card />
                                </Col>
                            </Row>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    </>
  )
}
