import React from "react";
import {FaCalendarAlt, FaGraduationCap, FaRegCommentDots, FaUserTie} from "react-icons/fa";
import s from "./style.module.scss";
import Button from "../../components/Elements/Button/Button";
import Title from "../../components/Elements/Title";
import ShowCase from "../../components/ShowCase";
import SwiperCore, {Autoplay, Pagination} from "swiper";
import {SwiperSlide, Swiper} from "swiper/react";
import {BsPlayCircleFill} from "react-icons/bs";
import Feedback from "../../components/Feedback";

const About = () => {
    SwiperCore.use([Autoplay]);

    return (
        <div className="">
            <ShowCase pagination="ABOUT US" height="70vh">
                <Title line2="About Us" textAlighCenter={true}/>
            </ShowCase>
            <div className={s.about}>
                <div className={`${s.about__box} container`}>
                    <div className={s.about__img}>
                        <div className={s.about__elemDiv}>
                            <div className={s.about__elem}>
                                <div className={s.about__elemIcon}>
                                    <FaUserTie color="#fff" size={20}/>
                                </div>
                                <div className={s.about__elemTitle}>Sardor Xudoyberdiyev</div>
                                <div className={s.about__elemText}>TESOL sertifikatiga ega</div>
                            </div>
                            <div className={s.about__elem}>
                                <div className={s.about__elemIcon}>
                                    <FaGraduationCap color="#fff" size={20}/>
                                </div>
                                <div className={s.about__elemTitle}>2000+</div>
                                <div className={s.about__elemText}>O'quvchilar</div>
                            </div>
                        </div>
                        <img src="/homePage/aboutIMG.png" alt="img"/>
                    </div>
                    <div className={s.about__text}>
                        <Title
                            line1="ABOUT US"
                            line3="We Have Best Online Education"
                            line4="Mening ismim Sardor, shu loyihaning asoschisi bo‘laman. 10 yil o‘qishdan yiqildim, oxiri ustoz bilan atigi 5 oy tayyorlanib yuqori ball bilan o'qishga kirdim va shunga qaror qildimki, ilm izlovchilari men kabi vaqt va umrini yoqotmay tezroq natijaga erishsinlar."
                        />
                        <li className={s.about__respImg}>
                            <div className={s.about__elemDiv}>
                                <div className={s.about__elem}>
                                    <div className={s.about__elemIcon}>
                                        <FaUserTie color="#fff" size={20}/>
                                    </div>
                                    <div className={s.about__elemTitle}>Sardor Xudoyberdiyev</div>
                                    <div className={s.about__elemText}>
                                        TESOL sertifikatiga ega
                                    </div>
                                </div>
                                <div className={s.about__elem}>
                                    <div className={s.about__elemIcon}>
                                        <FaGraduationCap color="#fff" size={20}/>
                                    </div>
                                    <div className={s.about__elemTitle}>2000+</div>
                                    <div className={s.about__elemText}>O'quvchilar</div>
                                </div>
                            </div>
                            <img src="/homePage/aboutIMG.png" alt="pic"/>
                        </li>
                        <ul className={s.about__list}>
                            <li>
                                <img src="/about/icons/checked.png" alt="alt"/> O'zbekistonda
                                turib Angliya va Kanadalik ustozlardan ONLAYN zoom darslar.
                            </li>
                            <li>
                                <img src="/about/icons/checked.png" alt="alt"/> Nol darajadan
                                boshlab Ingliz ustozlardan o'rganish bu 2 barobar tez.
                            </li>
                            <li>
                                <img src="/about/icons/checked.png" alt="alt"/> Onlayn ta'lim
                                sohasida 5 yildan ortiq tajribamiz sizga astoydil xizmat
                                ko'rsatamiz.
                            </li>
                        </ul>
                        <Button children="EXPLORE MORE"/>
                    </div>
                </div>
                <div className={`${s.about__logos} container`}>
                    <div>
                        <div className={s.about__logosBottom}></div>
                        <div className={s.about__logosTop}>
                            <img src="/homePage/logo1.png" alt=""/>
                            <img src="/homePage/logo2.png" alt=""/>
                            <img src="/homePage/logo3.png" alt=""/>
                        </div>
                    </div>
                </div>
            </div>
            <div className={s.team}>
                <div className={`${s.team__box} container`}>
                    <Title
                        line1="ACADEMIC PROGRAMS"
                        line3="Best Academics Programs"
                        textAlighCenter={true}
                    />
                    <Swiper
                        modules={[Pagination]}
                        spaceBetween={50}
                        slidesPerView={4}
                        pagination={{clickable: true}}
                        autoplay={{
                            delay: 5000,
                        }}
                        breakpoints={{
                            // when window width is >= 0px
                            0: {
                                slidesPerView: 1,
                            },
                            // when window width is >= 480px
                            480: {
                                slidesPerView: 2,
                            },
                            // when window width is >= 640px
                            640: {
                                slidesPerView: 2,
                            },
                            // when window width is >= 768px
                            768: {
                                slidesPerView: 3,
                            },
                            // when window width is >= 1100px
                            1100: {
                                slidesPerView: 4,
                            },
                        }}
                    >
                        <SwiperSlide>
                            <div className={s.team__card}>
                                <div className={s.team__img}></div>
                                <h1>Undergraduate</h1>
                                <p>
                                    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Sed,
                                    perspiciatis!
                                </p>
                                <a href="/#">APPLY NOW ⮞</a>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className={s.team__card}>
                                <div className={s.team__img}></div>
                                <h1>Undergraduate</h1>
                                <p>
                                    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Sed,
                                    perspiciatis!
                                </p>
                                <a href="/#">APPLY NOW ⮞</a>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className={s.team__card}>
                                <div className={s.team__img}></div>
                                <h1>Undergraduate</h1>
                                <p>
                                    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Sed,
                                    perspiciatis!
                                </p>
                                <a href="/#">APPLY NOW ⮞</a>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className={s.team__card}>
                                <div className={s.team__img}></div>
                                <h1>Undergraduate</h1>
                                <p>
                                    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Sed,
                                    perspiciatis!
                                </p>
                                <a href="/#">APPLY NOW ⮞</a>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className={s.team__card}>
                                <div className={s.team__img}></div>
                                <h1>Undergraduate</h1>
                                <p>
                                    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Sed,
                                    perspiciatis!
                                </p>
                                <a href="/#">APPLY NOW ⮞</a>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className={s.team__card}>
                                <div className={s.team__img}></div>
                                <h1>Undergraduate</h1>
                                <p>
                                    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Sed,
                                    perspiciatis!
                                </p>
                                <a href="/#">APPLY NOW ⮞</a>
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className={s.team__card}>
                                <div className={s.team__img}></div>
                                <h1>Undergraduate</h1>
                                <p>
                                    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Sed,
                                    perspiciatis!
                                </p>
                                <a href="/#">APPLY NOW ⮞</a>
                            </div>
                        </SwiperSlide>
                    </Swiper>
                </div>
            </div>
            <div className={s.live}>
                <div className={`${s.live__box} container`}>
                    <div className={s.live__img}>
                        <button>
                            <BsPlayCircleFill size={50}/>
                            YOZILING
                        </button>
                    </div>
                    <div className={s.live__text}>
                        <Title
                            line1="NAVBATDAGI JONLI SPEAKING DARS"
                            line3="Native Speakerning Speaking darsiga yoziling"
                        />
                        <button>
                            <div>
                                <p>
                                    <span>07</span> Apr, 2023
                                </p>
                                <h6>20:00-21:30</h6>
                                <h5>ONLINE</h5>
                                <h4>Teacher Samantha bilan zoom dars</h4>
                            </div>
                        </button>
                        <button>
                            <div>
                                <p>
                                    <span>07</span> Apr, 2023
                                </p>
                                <h6>20:00-21:30</h6>
                                <h5>ONLINE</h5>
                                <h4>Teacher Samantha bilan zoom dars</h4>
                            </div>
                        </button>
                        <button>
                            <div>
                                <p>
                                    <span>07</span> Apr, 2023
                                </p>
                                <h6>20:00-21:30</h6>
                                <h5>ONLINE</h5>
                                <h4>Teacher Samantha bilan zoom dars</h4>
                            </div>
                        </button>
                    </div>
                </div>
            </div>

            <div className={s.feedback}>
                <Feedback/>
            </div>
            <div className={s.blog}>
                <div className={`${s.blog__box} container`}>
                    <Title
                        line1="SIZ UCHUN OTA FOYALI MALUMOTLAR"
                        line3="Eng songgi bloglar"
                        textAlighCenter={true}
                    />
                    <div className={s.blog__cards}>
                        <div className={s.blog__card}>
                            <div className={s.blog__img}></div>
                            <div className={s.blog__text}>
                                <p>Lorem ipsum dolor, sit amet consectetur adipisicing.</p>
                                <div className={s.blog__comms}>
                                    <h5>
                                        <FaCalendarAlt color="#ff5219"/>
                                        07 Jan, 2022
                                    </h5>
                                    <h5>
                                        <FaRegCommentDots color="#ff5219"/>3 Comments
                                    </h5>
                                </div>
                            </div>
                        </div>
                        <div className={s.blog__card}>
                            <div className={s.blog__img}></div>
                            <div className={s.blog__text}>
                                <p>Lorem ipsum dolor, sit amet consectetur adipisicing.</p>
                                <div className={s.blog__comms}>
                                    <h5>
                                        <FaCalendarAlt color="#ff5219"/>
                                        07 Jan, 2022
                                    </h5>
                                    <h5>
                                        <FaRegCommentDots color="#ff5219"/>3 Comments
                                    </h5>
                                </div>
                            </div>
                        </div>
                        <div className={s.blog__card}>
                            <div className={s.blog__img}></div>
                            <div className={s.blog__text}>
                                <p>Lorem ipsum dolor, sit amet consectetur adipisicing.</p>
                                <div className={s.blog__comms}>
                                    <h5>
                                        <FaCalendarAlt color="#ff5219"/>
                                        07 Jan, 2022
                                    </h5>
                                    <h5>
                                        <FaRegCommentDots color="#ff5219"/>3 Comments
                                    </h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default About;
