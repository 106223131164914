// import BlackImg from '../../assets/black-bg.png'
// import AvatarImg from '../../assets/avatar.png'
import { Button } from "../Elements";
// import { Modal as AntdModal, Input, message } from "antd";
// import Form from "antd/es/form/Form";
// import axios from "axios";

import "./stye.scss";
// import { useState } from "react";

export default function CourseCard({
  tag1,
  tag2,
  title,
  userName,
  link,
  avaImg,
  courseImg,
}) {
  // Modal
  // const [formData] = Form.useForm();
  // // const [loading, setLoading] = useState(false);
  // // const handleOk = () => {};
  // const [isModalOpen, setIsModelOpen] = useState(false);

  // const handleChange = (e) => {
  //   const { value } = e.target;
  //   const formattedValue = formatPhoneNumber(value);
  //   formData.setFieldValue("phone", formattedValue);
  // };

  // const formatPhoneNumber = (value) => {
  //   // Удаляем все нецифровые символы из введенного значения
  //   const cleanedValue = value.replace(/\D/g, "");

  //   // Форматируем номер в требуемый формат
  //   let formattedValue = cleanedValue;
  //   if (cleanedValue.length > 2) {
  //     formattedValue = cleanedValue.replace(
  //       /(\d{2})(\d{3})(\d{2})(\d{2})/,
  //       "($1) $2-$3-$4"
  //     );
  //   } else if (cleanedValue.length > 0) {
  //     formattedValue = cleanedValue.replace(/(\d{1,2})/, "($1");
  //   }

  //   return formattedValue;
  // };
  // const cencelModal = () => {
  //   formData.resetFields();
  //   setIsModelOpen(false);
  // };
  // const openModal = () => {
  //   setIsModelOpen(true);
  // };

  // const handleSubmit = async (data) => {
  //   try {
  //     // send to server
  //     const resp = axios.post("https://myteacher.uz/api/register", {
  //       name: data.fullName,
  //       phone: `998${data.phone
  //         ?.replaceAll(" ", "")
  //         .replaceAll("-", "")
  //         .replace("(", "")
  //         .replace(")", "")}`,
  //       comment: data.message,
  //     });
  //     resp.then((e) => {
  //       console.log(e);
  //     });
  //   } catch (e) {
  //     // show notify
  //     message.error("Something went wrong!");
  //     console.log(e);
  //   } finally {
  //     setIsModelOpen(false);
  //   }
  // };
  return (
    <div className="course-card">
      <div className="course-card__header">
        <img src={courseImg} alt="course" width="300" />
        <div className="actions d-flex align-items-center">
          <Button size="sm" variant="primary">
            {tag1}
          </Button>
          <Button size="sm" variant="info" className="ms-4">
            {tag2}
          </Button>
        </div>
      </div>
      <div className="course-card__body">
        <div className="course-card__title">{title}</div>
        <div className="course-card__data">
          <div className="d-flex align-items-center">
            <img src={avaImg} alt="user avatar" className="course-card__ava" />
            <div className="user-name">{userName}</div>
          </div>
          <div className="rate">4.9 (2k)</div>
        </div>
        <div className="course-card__footer">
          <Button to={link} children="BATAFSIL" />
        </div>
      </div>
      {/* //Modal */}
      {/* <AntdModal
        open={isModalOpen}
        footer={null}
           // closable={false}
           onCancel={()=>{
            setIsModelOpen(false)
          }}
        >
          <p><strong style={{fontSize: "20px"}}>Alaykum assalom, MyTeacherga xush kelibsiz! Iltimos ismingiz va telefon raqamingizni qoldiring. Siz bilan tez orada bog'lanamiz.</strong></p>
       <Form
          name="normal_login"
          className="login-form"
          onFinish={handleSubmit}
          form={formData}
        >
          <Form.Item
            key={"1"}
            name={"fullName"}
            label={"Ismingiz"}
            rules={[
              { required: true, message: "Iltimos ismingizni kiriting!" },
            ]}
          >
            <Input
              type={"text"}
              name={"fullName"}
              placeholder="Sizning ismingiz"
            />
          </Form.Item>
          <Form.Item
            key={"1"}
            name={"phone"}
            label={"Raqamingiz"}
            rules={[
              { required: true, message: "Iltimos raqmingizni kiriting!" },
            ]}
          >
            <Input
              prefix="+998"
              onChange={handleChange}
              maxLength={14}
              type={"tel"}
              name={"number"}
              placeholder="(__) ___ - __ - __"
            />
          </Form.Item>
          <Form.Item
            key={"1"}
            name={"message"}
            label={"Qo'shimcha"}
            // rules={[{ required: true, message: "Iltimos malumot kiriting!" }]}
          >
            <Input.TextArea
              rows={3}
              name={"Message"}
              placeholder="Izoh qoldirishingiz mumkin"
            />
          </Form.Item>
          <div className="flex items-center justify-end">
            <Button
              variant="secondary"
              onClick={cencelModal}
              size="sm"
              children="Yopish"
              style={{ margin: "0 10px" }}
            />
            <Button htmlType="submit" children="Jo'natish" size="sm" />
          </div>
        </Form>
      </AntdModal> */}
    </div>
  );
}
