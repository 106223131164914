import { Route, Routes } from "react-router-dom";
import Homepage from "./pages/Homepage";
import About from "./pages/About";
import Blog from "./pages/Blog";
import Layout from "./components/Layout/Layout";
import PaymeForm from "./components/PaymeForm";
import PaymePage from "./pages/PaymePage";
import "./app.scss";

// global styles
import "bootstrap/dist/css/bootstrap.min.css";
import "./styles/index.scss";

import FAQ from "./pages/Faq";
import Pricing from "./pages/Pricings";
import Contact from "./pages/Contact";
import Instructor from "./pages/Instructor";
import InstructorDiteils from "./pages/InstructorDiteils";
import BlogDiteils from "./components/BlogDiteils";
import Courses from "./pages/Courses";
import EventDiteil from "./pages/EventDiteil";
import CourseDiteil from "./pages/CourseDetail";
import PerfSpoken from "./pages/CourseDetail/PerfSpoken";
import IELTS from "./pages/CourseDetail/IELTS";
import RusTili from "./pages/CourseDetail/RusTili";
import Test from "./pages/Test";
function App() {
  return (
    <Routes>
      <Route path={"/"} element={<Layout />}>
        <Route index element={<Homepage />} />
        <Route path={"/about"} element={<About />} />
        <Route path={"/courses"} element={<Courses />} />
        <Route path={"/courses/PerfSponek"} element={<CourseDiteil />} />
        <Route path={"/courses/diteiled"} element={<CourseDiteil />} />
        <Route path={"/courses/perf-spoken"} element={<PerfSpoken />} />
        <Route path={"/courses/ielts"} element={<IELTS />} />
        <Route path={"/courses/rus-tili"} element={<RusTili />} />
        <Route path={"/blogs"} element={<Blog />}>
          <Route path={"/blogs/diteils/:id"} element={<BlogDiteils />} />
        </Route>
        <Route path={"/payme"} element={<PaymeForm />} />
        <Route path={"/faq"} element={<FAQ />} />
        <Route path={"/pricing"} element={<Pricing />} />
        <Route path={"/contact"} element={<Homepage />} />
        <Route path={"/instructor"} element={<Instructor />} />
        <Route
          path={"/instructor/diteils/:id"}
          element={<InstructorDiteils />}
        />
        <Route path={"/event-diteil"} element={<EventDiteil />} />
      </Route>
      <Route path="/test" element={<Test />} />
      <Route path="/paymePage" element={<PaymePage />} />
    </Routes>
  );
}

export default App;
