import React, { useState } from "react";
import "./style.scss";
import {
  Button,
  Form,
  Radio,
  Space,
  Modal,
  Steps,
  Modal as AntdModal,
  message,
  Input,
} from "antd";
import { testData } from "./dast";
import { Link } from "react-router-dom";
import axios from "axios";
import { Title } from "../../components/Elements";

const Test = () => {
  const [item, setItem] = useState(0);
  const [isSelected, setIsSelected] = useState(false);
  const [form] = Form.useForm();
  // Modal
  const [open, setOpen] = useState(false);
  const [result, setResult] = useState(0);
  const showModal = () => {
    setOpen(true);
  };

  const handleCancel = () => {
    setOpen(false);
    form.resetFields();
    setItem(0);
    window.scrollTo(0, 0);
  };

  // Steps

  const items = [
    {
      title: "A1",
      description: "Starter",
    },
    {
      title: "A2",
      description: "Elementary/Pre Intermediate",
    },
    {
      title: "B1",
      description: "Pre Intermediate/Intermediate",
    },
    {
      title: "B2",
      description: "Upper Intermediate",
    },
    {
      title: "C1/C2",
      description: "Advanced",
    },
  ];

  const onFinish = (values) => {
    let performance = 0;
    console.log(values);
    let i = 0;
    for (const key in values) {
      if (values[key] === testData[i].currect.key) {
        console.log("+", key);
        performance++;
      } else console.log("-");
      i++;
    }
    console.log(performance);

    setResult(
      performance <= 5
        ? 0 //A1
        : performance > 5 && performance <= 11
        ? 1 //A2
        : performance > 11 && performance <= 23
        ? 2 //B1
        : performance > 23 && performance <= 27
        ? 3 //B2
        : 4
    ); //C1/C2
    // openModal();
    showModal();

  };

  // Modal Form
  const [formData] = Form.useForm();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleChange = (e) => {
    const { value } = e.target;
    const formattedValue = formatPhoneNumber(value);
    formData.setFieldValue("phone", formattedValue);
  };

  const formatPhoneNumber = (value) => {
    // Удаляем все нецифровые символы из введенного значения
    const cleanedValue = value.replace(/\D/g, "");

    // Форматируем номер в требуемый формат
    let formattedValue = cleanedValue;
    if (cleanedValue.length > 2) {
      formattedValue = cleanedValue.replace(
        /(\d{2})(\d{3})(\d{2})(\d{2})/,
        "($1) $2-$3-$4"
      );
    } else if (cleanedValue.length > 0) {
      formattedValue = cleanedValue.replace(/(\d{1,2})/, "($1");
    }

    return formattedValue;
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const handleSubmit = async (data) => {
    try {
      // send to server
      const resp = axios.post("https://myteacher.uz/api/register", {
        name: data.fullName,
        phone: `998${data.phone
          ?.replaceAll(" ", "")
          .replaceAll("-", "")
          .replace("(", "")
          .replace(")", "")}`,
        comment: data.message,
      });
      resp
        .then(() => {
          setIsModalOpen(false)
          showModal();
        });
    } catch (e) {
      // show notify
      message.error("Something went wrong!");
    } finally{
      setIsModalOpen(false)
      showModal();
    }
  };

  return (
    <div className={"test g-container"}>
      <header>
        <div className="test__logo">
          <Link to="/">
            <img src="/about/logo/logo.svg" alt="img" />
          </Link>
        </div>
      </header>
      <div className="test__title">
      <Title line1={"DARAJA SINOVI"} line3={"Test Your English"} line4={"Keling, sizning bilim darajangizni tekshirib ko’ramiz. O’z darajangizni bilib olish uchun, iltimos, manabu 30 ta savoldan iborat testni bajaring. Test yakunida sizning hozirgi bilim darajangiz ma’lum bo’ladi! OMAD!"} textAlighCenter={true} /> </div>
      <Form
        form={form}
        onFinish={onFinish}
        layout="vertical"
        style={{ maxWidth: "100%", width: "100%", margin: "0 auto" }}
      >
        {testData.map((e) => {
          return (
            <Form.Item
              key={e.key}
              name={`question${e.key}`}
              label={`${e.key}. ${e.question}`}
            >
              <Radio.Group
                disabled={item + 1 === e.key ? false : true}
                onChange={(e) => {
                  setIsSelected(e.target.value);
                }}
              >
                <Space direction="vertical">
                  {e.options.map((op) => {
                    return (
                      <Radio value={op.key} key={op.key}>
                        {op.value}
                      </Radio>
                    );
                  })}
                  <Button
                    id={e.key}
                    type="primary"
                    onClick={(btn) => {
                      console.log(btn.target, e.key);
                      setItem(e.key);
                      setIsSelected(false);
                    }}
                    htmlType={
                      item + 1 === testData.length ? "submit" : "button"
                    }
                    disabled={!(item + 1 === e.key)}
                    hidden={
                      item + 1 === testData.length || item === testData.length
                    }
                  >
                    {item >= e.key || (isSelected && item + 1 === e.key)
                      ? "Submit"
                      : "Skip the Question"}
                  </Button>
                </Space>
              </Radio.Group>
            </Form.Item>
          );
        })}
        <Button
          disabled={!(item + 1 === testData.length)}
          type="primary"
          htmlType="submit"
        >
          Finish the Test
        </Button>
      </Form>

      {/* Modal */}
      <Modal
        open={open}
        title={`Sizning darajangiz ${
          result === 0
            ? "A1 (Starter)"
            : result === 1
            ? "A2 (Elementary/Pre Intermediate)"
            : result === 2
            ? "B1 (Elementary/Pre Intermediate)"
            : result === 3
            ? "B2 (Upper Intermediate)"
            : "C1/C2 (Advanced)"
        }`}
        onCancel={handleCancel}
        width={1000}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Qayta yechish
          </Button>,
          <Button key="submit" type="primary">
            <Link to="/">Back to Home</Link>
          </Button>,
        ]}
      >
        <Steps
          current={result}
          size="default"
          labelPlacement="vertical"
          items={items}
        />
      </Modal>

      {/* Form modal */}

      <AntdModal
        open={isModalOpen}
        footer={null}
        closable={false}
      >
        <p>
          <strong style={{ fontSize: "20px" }}>
            Test javobini BEPUL bilish uchun ro'yxatdan o'ting!
          </strong>
        </p>
        <Form
          name="normal_login"
          className="login-form"
          onFinish={handleSubmit}
          form={formData}
        >
          <Form.Item
            key={"1"}
            name={"fullName"}
            label={"Ismingiz"}
            rules={[
              { required: true, message: "Iltimos ismingizni kiriting!" },
            ]}
          >
            <Input
              type={"text"}
              name={"fullName"}
              placeholder="Sizning ismingiz"
            />
          </Form.Item>
          <Form.Item
            key={"1"}
            name={"phone"}
            label={"Raqamingiz"}
            rules={[
              { required: true, message: "Iltimos raqmingizni kiriting!" },
            ]}
          >
            <Input
              prefix="+998"
              onChange={handleChange}
              maxLength={14}
              type={"tel"}
              name={"number"}
              placeholder="(__) ___ - __ - __"
            />
          </Form.Item>
          <Form.Item
            key={"1"}
            name={"message"}
            label={"Qo'shimcha"}
            // rules={[{ required: true, message: "Iltimos malumot kiriting!" }]}
          >
            <Input.TextArea
              rows={3}
              name={"Message"}
              placeholder="Izoh qoldirishingiz mumkin"
            />
          </Form.Item>
          <Form.Item valuePropName="checked">
          Ro'yxatdan o'tish bilan <a href="https://docs.google.com/document/d/1VZZqIF2vX_1D008AEvgHViZXZObIh7nNaPv0cSTTU0g/edit">
                  <span style={{color : "blue"}}> Ommaviy oferta va foydalanish shartlariga</span>
                  </a>{" "}
                  rozilik bildirasiz.
              </Form.Item>
          <div className="flex items-center justify-end">
            <Button type="primary" htmlType="submit" children="Javobni bilish" size="sm" />
          </div>
        </Form>
      </AntdModal>
    </div>
  );
};

export default Test;
