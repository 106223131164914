import React from "react";
import {AiFillInstagram} from "react-icons/ai";
import {FaFacebookF, FaTelegramPlane} from "react-icons/fa";
// Import Swiper React components
import {Swiper, SwiperSlide} from "swiper/react";
// Import Swiper styles
import "swiper/css";
import s from "./style.module.scss";
import "swiper/css/pagination";
import SwiperCore, {Pagination, Autoplay} from "swiper";
import {Title} from "../Elements";

const instructors = [
    {
        name: 'Javlonbek Bosimov',
        position: 'CSO',
        img: 'instructor6.png',
        socials: {facebook: '', instagram: 'https://www.instagram.com/b.javlonbek/', telegram: 'https://t.me/CSO_myteacher'}
    },
    {
        name: 'Xolmatov Muzaffar',
        position: 'Maslahatchi mutaxassis',
        img: 'instructor1.png',
        socials: {facebook: '', instagram: '', telegram: 'https://t.me/MYTEACHER_M_X'}
    },
    {
        name: 'Abduraxmonov Nurbek',
        position: 'Maslahatchi mutaxassis',
        img: 'instructor2.png',
        socials: {facebook: '', instagram: '', telegram: 'https://t.me/Myteacher_1201'}
    },
    {
        name: 'Ilxomjonovich Ibrohim',
        position: 'Maslahatchi mutaxassis',
        img: 'instructor4.png',
        socials: {facebook: '', instagram: '', telegram: 'https://t.me/Myteacher_uzb'}
    },
    {
        name: 'O‘ngarov Zafar',
        position: 'Maslahatchi mutaxassis',
        img: 'instructor5.png',
        socials: {facebook: '', instagram: '', telegram: 'https://t.me/Myteacher_7162'}
    },
]

const OurTeam = () => {
    SwiperCore.use([Autoplay]);
    return (
        <div className={s.team}>
            <Title
                textAlighCenter={true}
                line1="SIZGA XIZMAT QILISHDAN MAMNUNMIZ"
                line3="Bizning professional jamoa"
            />
            <Swiper
                modules={[Pagination]}
                spaceBetween={30}
                slidesPerView={4}
                pagination={{clickable: true}}
                breakpoints={{
                    // when window width is >= 0px
                    0: {
                        slidesPerView: 1,
                    },
                    // when window width is >= 480px
                    480: {
                        slidesPerView: 1,
                    },
                    // when window width is >= 640px
                    640: {
                        slidesPerView: 3,
                    },
                    // when window width is >= 768px
                    768: {
                        slidesPerView: 3,
                    },
                    // when window width is >= 1100px
                    1100: {
                        slidesPerView: 4,
                    },
                }}
            >
                {instructors.map((data, idx) => (
                    <SwiperSlide className={s.team__cardDiv} key={idx}>
                        <div className={s.team__card}>
                            <div className={s.team__img}>
                                <img src={`/homePage/${data.img}`} alt="instructor" width='250'/>
                                <div className={s.team__icons}>
                                    <a href={data.socials.facebook} target="_blank" className={s.team__icon} rel="noreferrer">
                                        <FaFacebookF color="#fff"/>
                                    </a>
                                    <a href={data.socials.instagram} target="_blank" className={s.team__icon} rel="noreferrer">
                                        <AiFillInstagram color="#fff"/>
                                    </a>
                                    <a href={data.socials.telegram} target="_blank" className={s.team__icon} rel="noreferrer">
                                        <FaTelegramPlane color="#fff"/>
                                    </a>
                                </div>
                            </div>
                            <h1>{data.name}</h1>
                            <p>{data.position}</p>
                        </div>
                    </SwiperSlide>
                ))}

            </Swiper>
        </div>
    );
};

export default OurTeam;
