import React, { useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import s from "./style.module.scss";
import "swiper/css/pagination";
import SwiperCore, { Pagination, Autoplay } from "swiper";
import { Title, Button } from "../Elements";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Modal as AntdModal, Input, message } from "antd";
import Form from "antd/es/form/Form";
import axios from "axios";


const Prices = ({ course }) => {

  SwiperCore.use([Autoplay]);
  const dispatch = useDispatch();
  const Click = (e) => {
    dispatch({ type: e.currentTarget.id });
    localStorage.setItem("selectedPlan", e.currentTarget.id);
  };
  const ClickCourse = (e) => {
    localStorage.setItem("selectedCourse", e.currentTarget.id);
  };


    // Modal
    const [formData] = Form.useForm();
    // const [loading, setLoading] = useState(false);
    const [isModalOpen, setIsModelOpen] = useState(false);
  
  
    const handleChange = (e) => {
      const { value } = e.target;
      const formattedValue = formatPhoneNumber(value);
      formData.setFieldValue("phone", formattedValue);
    };
  
    const formatPhoneNumber = (value) => {
      // Удаляем все нецифровые символы из введенного значения
      const cleanedValue = value.replace(/\D/g, "");
  
      // Форматируем номер в требуемый формат
      let formattedValue = cleanedValue;
      if (cleanedValue.length > 2) {
        formattedValue = cleanedValue.replace(
          /(\d{2})(\d{3})(\d{2})(\d{2})/,
          "($1) $2-$3-$4"
        );
      } else if (cleanedValue.length > 0) {
        formattedValue = cleanedValue.replace(/(\d{1,2})/, "($1");
      }
  
      return formattedValue;
    };
    const cencelModal = () => {
      formData.resetFields();
      setIsModelOpen(false);
    };
    const openModal = () => {
      setIsModelOpen(true);
    };
  
    const handleSubmit = async (data) => {
      try {
        // send to server
        const resp = axios.post("https://myteacher.uz/api/register", {
          name: `$$hot(olov)  ${data.fullName}`,
          phone: `998${data.phone
            ?.replaceAll(" ", "")
            .replaceAll("-", "")
            .replace("(", "")
            .replace(")", "")}`,
          comment: data.message,
        });
        resp.then((e) => {
          console.log(e);
        });
      } catch (e) {
        // show notify
        message.error("Something went wrong!");
      } finally {
        setIsModelOpen(false);
      }
    };
  return (
    <div className={s.prices}>
      <Title
        line1="NARXLAR SIZ OYLAGANDAN ANCHA ARZON"
        line3="Kurslarimiz Narxi qancha?"
        textAlighCenter={true}
      />
      {course === "PerfSpoken" ? (
        <div className={s.prices__cards} onClick={ClickCourse} id="PerfSpoken">
          {/* <Title line3="Perfectly Spoken kursi" /> */}
          <Swiper
            modules={[Pagination]}
            spaceBetween={20}
            slidesPerView={4}
            pagination={{ clickable: true }}
            autoplay={{
              delay: 5000,
            }}
            breakpoints={{
              // when window width is >= 0px
              0: {
                slidesPerView: 1,
              },
              // when window width is >= 480px
              480: {
                slidesPerView: 2,
              },
              // when window width is >= 640px
              640: {
                slidesPerView: 3,
              },
              // when window width is >= 768px
              768: {
                slidesPerView: 4,
              },
              // when window width is >= 1100px
              1200: {
                slidesPerView: 4,
              },
            }}
          >
            <SwiperSlide>
              <div className={s.prices__cardPerf} id="Econom" onClick={Click}>
                {/* <Link to="/paymePage"> */}
                  <div className={s.prices__top}>
                    <h1>Econom</h1>
                    <p>Faqat mustaqil o'rganuvchilarga mos</p>
                    <h5>
                      <span>799.000</span>/6 oy
                    </h5>
                  </div>
                  <ul className={s.prices__bottom}>
                    <li>
                      <img src="/homePage/checked2.png" alt="img" />6 oylik
                      to'liq platforma.
                    </li>
                    <li>
                      <img src="/homePage/checked2.png" alt="img" />
                      Sertifikat.
                    </li>
                    <li>
                      <img src="/homePage/xchecked2.png" alt="img" />
                      <del>Doimiy qo'llab quvatlash.</del>
                    </li>
                    <li>
                      <img src="/homePage/xchecked2.png" alt="img" />
                      <del>Haftada 2 marta NS bilan zoomda muloqot.</del>
                    </li>
                    <li>
                      <img src="/homePage/xchecked2.png" alt="img" />
                      <del>Kurator bilan dars (haftada 2 marta).</del>
                    </li>
                    <li>
                      <img src="/homePage/xchecked2.png" alt="img" />
                      <del>4 oylik IELTS platformasi Sovg'aga</del>
                    </li>
                    <li>
                      <Button onClick={()=>{openModal()}}>SOTIB OLISH</Button>
                    </li>
                  </ul>
                {/* </Link> */}
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className={s.prices__cardPerf} id="Standart" onClick={Click}>
                {/* <Link to="/paymePage"> */}
                  <div className={s.prices__top}>
                    <h1>Standart</h1>
                    <p>O'qituvchi va talabalarga mos</p>
                    <h5>
                      <span>1.299.000</span>/6 oy
                    </h5>
                  </div>
                  <ul className={s.prices__bottom}>
                    <li>
                      <img src="/homePage/checked2.png" alt="img" />6 oylik
                      to'liq platforma.
                    </li>
                    <li>
                      <img src="/homePage/checked2.png" alt="img" />
                      Sertifikat.
                    </li>
                    <li>
                      <img src="/homePage/checked2.png" alt="img" />
                      Doimiy qo'llab quvatlash.
                    </li>
                    <li>
                      <img src="/homePage/xchecked2.png" alt="img" />
                      <del>Haftada 2 marta NS bilan zoomda muloqot.</del>
                    </li>
                    <li>
                      <img src="/homePage/checked2.png" alt="img" />
                      Kurator bilan dars (haftada 1 marta).
                    </li>
                    <li>
                      <img src="/homePage/xchecked2.png" alt="img" />
                      <del>4 oylik IELTS platformasi Sovg'aga</del>
                    </li>
                    <li>
                      <Button onClick={()=>{openModal()}}>SOTIB OLISH</Button>
                    </li>
                  </ul>
                {/* </Link> */}
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className={s.prices__cardPerf} id="Premium" onClick={Click}>
                {/* <Link to="/paymePage"> */}
                  <div className={s.prices__top}>
                    <h1>Premium</h1>
                    <p>"Speaking"ni oshirishga mos</p>
                    <h5>
                      <span>1.999.000</span>/6 oy
                    </h5>
                  </div>
                  <ul className={s.prices__bottom}>
                    <li>
                      <img src="/homePage/checked2.png" alt="img" />6 oylik
                      to'liq platforma.
                    </li>
                    <li>
                      <img src="/homePage/checked2.png" alt="img" />
                      Sertifikat.
                    </li>
                    <li>
                      <img src="/homePage/checked2.png" alt="img" />
                      Doimiy qo'llab quvatlash.
                    </li>
                    <li>
                      <img src="/homePage/checked2.png" alt="img" />
                      Haftada 2 marta NS bilan zoomda muloqot.
                    </li>
                    <li>
                      <img src="/homePage/checked2.png" alt="img" />
                      Kurator bilan dars (haftada 2 marta).
                    </li>
                    <li>
                      <img src="/homePage/xchecked2.png" alt="img" />
                      <del>4 oylik IELTS platformasi Sovg'aga </del>
                    </li>
                    <li>
                      <Button onClick={()=>{openModal()}}>SOTIB OLISH</Button>
                    </li>
                  </ul>
                {/* </Link> */}
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className={s.prices__cardPerf} id="VIP" onClick={Click}>
                {/* <Link to="/paymePage"> */}
                  <div className={s.prices__top}>
                    <h1>VIP</h1>
                    <p>Yuqori marrani ko'zlaganlarga mos</p>
                    <h5>
                      <span>2.799.000</span>/6 oy
                    </h5>
                  </div>
                  <ul className={s.prices__bottom}>
                    <li>
                      <img src="/homePage/checked2.png" alt="img" />6 oylik
                      to'liq platforma.
                    </li>
                    <li>
                      <img src="/homePage/checked2.png" alt="img" />
                      Sertifikat.
                    </li>
                    <li>
                      <img src="/homePage/checked2.png" alt="img" />
                      Doimiy qo'llab quvatlash.
                    </li>
                    <li>
                      <img src="/homePage/checked2.png" alt="img" />
                      Haftada 2 marta NS bilan zoomda muloqot.
                    </li>
                    <li>
                      <img src="/homePage/checked2.png" alt="img" />
                      Kurator bilan dars (haftada 2 marta).
                    </li>
                    <li>
                      <img src="/homePage/checked2.png" alt="img" />4 oylik
                      IELTS platformasi Sovg'aga
                    </li>

                    <li>
                      <Button onClick={()=>{openModal()}}>SOTIB OLISH</Button>
                    </li>
                  </ul>
                {/* </Link> */}
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className={s.prices__cardPerf} id="Comfort" onClick={Click}>
                {/* <Link to="/paymePage"> */}
                  <div className={s.prices__top}>
                    <h1>Comfort (oyma-oy)</h1>
                    <p>Oyma-oy to'lab o'rganish</p>
                    <h5>
                      <span>499.000</span>/Bir oylik
                    </h5>
                  </div>
                  <ul className={s.prices__bottom}>
                    <li>
                      <img src="/homePage/checked2.png" alt="img" />
                      Sertifikat.
                    </li>
                    <li>
                      <img src="/homePage/checked2.png" alt="img" />6 oylik
                      to'liq platforma.
                    </li>
                    <li>
                      <img src="/homePage/checked2.png" alt="img" />
                      Doimiy qo'llab quvatlash.
                    </li>
                    <li>
                      <img src="/homePage/checked2.png" alt="img" />
                      Haftada 2 marta NS bilan zoomda muloqot.
                    </li>
                    <li>
                      <img src="/homePage/checked2.png" alt="img" />
                      Kurator bilan dars (haftada 2 marta).
                    </li>
                    <li>
                      <img src="/homePage/checked2.png" alt="img" />
                      Guruhda maksimum 25 kishi
                    </li>
                    <li>
                      <Button onClick={()=>{openModal()}}>SOTIB OLISH</Button>
                    </li>
                  </ul>
                {/* </Link> */}
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      ) : course === "IELTS" ? (
        <div className={s.prices__cards} onClick={ClickCourse} id="IELTS">
          {/* <Title line3="Academic IELTS SUCCESS kursi" /> */}
          <Swiper
            modules={[Pagination]}
            spaceBetween={20}
            slidesPerView={4}
            pagination={{ clickable: true }}
            autoplay={{
              delay: 5000,
            }}
            breakpoints={{
              // when window width is >= 0px
              0: {
                slidesPerView: 1,
              },
              // when window width is >= 480px
              480: {
                slidesPerView: 2,
              },
              // when window width is >= 640px
              640: {
                slidesPerView: 3,
              },
              // when window width is >= 768px
              768: {
                slidesPerView: 4,
              },
              // when window width is >= 1100px
              1200: {
                slidesPerView: 4,
              },
            }}
          >
            <SwiperSlide>
              <div className={s.prices__card} id="Econom" onClick={Click}>
                {/* <Link to="/paymePage"> */}
                  <div className={s.prices__top}>
                    <h1>Econom</h1>
                    <p>Faqat mustaqil o'rganuvchilarga mos</p>
                    <h5>
                      <span>399.000</span>/Bir yillik
                    </h5>
                  </div>
                  <ul className={s.prices__bottom}>
                    <li>
                      <img src="/homePage/checked2.png" alt="img" />
                      Platforma (1 yil).
                    </li>
                    <li>
                      <img src="/homePage/xchecked2.png" alt="img" />
                      <del>Doimiy qo'llab quvvatlash. </del>
                    </li>
                    <li>
                      <img src="/homePage/xchecked2.png" alt="img" />
                      <del>NS bilan zoomda dars 1/week. </del>
                    </li>
                    <li>
                      <img src="/homePage/xchecked2.png" alt="img" />
                      <del>Kurator bilan dars (haftada 1 marta). </del>
                    </li>
                    <li>
                      <img src="/homePage/xchecked2.png" alt="img" />
                      <del>
                        IELTS 8 o'qituvchilarning yordami (haftada 2 marta).{" "}
                      </del>
                    </li>
                    <li>
                      <img src="/homePage/xchecked2.png" alt="img" />
                      <del>Spaking club IELTS 8 ustoz ishtirokida. </del>
                    </li>
                    <li>
                      <img src="/homePage/xchecked2.png" alt="img" />
                      <del>Perfectly spoken platform 2 oy. </del>
                    </li>
                    <li>
                      <img src="/homePage/xchecked2.png" alt="img" />
                      <del>Mr. Adrian bilan Speaking (1 oyda bir).</del>
                    </li>
                    <li>
                      <img src="/homePage/xchecked2.png" alt="img" />
                      <del>Essay checking (6 marta). </del>
                    </li>
                    <li>
                      <Button onClick={()=>{openModal()}}>SOTIB OLISH</Button>
                    </li>
                  </ul>
                {/* </Link> */}
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className={s.prices__card} id="Standart" onClick={Click}>
                {/* <Link to="/paymePage"> */}
                  <div className={s.prices__top}>
                    <h1>Standart</h1>
                    <p>O'qituvchi va talabalarga mos</p>
                    <h5>
                      <span>899.000</span>/4 oy
                    </h5>
                  </div>
                  <ul className={s.prices__bottom}>
                    <li>
                      <img src="/homePage/checked2.png" alt="img" />
                      Platforma (1 yil).
                    </li>
                    <li>
                      <img src="/homePage/checked2.png" alt="img" />
                      Doimiy qo'llab quvvatlash.
                    </li>
                    <li>
                      <img src="/homePage/checked2.png" alt="img" />
                      Haftada 1 marta NS bilan zoomda muloqot.
                    </li>
                    <li>
                      <img src="/homePage/checked2.png" alt="img" />
                      Kurator bilan dars (haftada 1 marta).
                    </li>
                    <li>
                      <img src="/homePage/xchecked2.png" alt="img" />
                      <del>
                        IELTS 8 o'qituvchilarning yordami (haftada 2 marta).{" "}
                      </del>
                    </li>
                    <li>
                      <img src="/homePage/xchecked2.png" alt="img" />
                      <del>Spaking club IELTS 8 ustoz ishtirokida. </del>
                    </li>
                    <li>
                      <img src="/homePage/xchecked2.png" alt="img" />
                      <del>Perfectly spoken platform 2 oy. </del>
                    </li>
                    <li>
                      <img src="/homePage/xchecked2.png" alt="img" />
                      <del>Mr. Adrian bilan Speaking (1 oyda bir).</del>
                    </li>
                    <li>
                      <img src="/homePage/xchecked2.png" alt="img" />
                      <del>Essay checking (6 marta).</del>
                    </li>
                    <li>
                      <Button onClick={()=>{openModal()}}>SOTIB OLISH</Button>
                    </li>
                  </ul>
                {/* </Link> */}
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className={s.prices__card} id="Premium" onClick={Click}>
                {/* <Link to="/paymePage"> */}
                  <div className={s.prices__top}>
                    <h1>Premium</h1>
                    <p>"Speaking"ni oshirishga mos</p>
                    <h5>
                      <span>1.499.000</span>/Bir yillik
                    </h5>
                  </div>
                  <ul className={s.prices__bottom}>
                    <li>
                      <img src="/homePage/checked2.png" alt="img" />
                      Platforma (1 yil).
                    </li>
                    <li>
                      <img src="/homePage/checked2.png" alt="img" />
                      Doimiy qo'llab quvvatlash.
                    </li>
                    <li>
                      <img src="/homePage/checked2.png" alt="img" />
                      Haftada 1 marta NS bilan zoomda muloqot.
                    </li>
                    <li>
                      <img src="/homePage/checked2.png" alt="img" />
                      Kurator bilan dars (haftada 1 marta).
                    </li>
                    <li>
                      <img src="/homePage/checked2.png" alt="img" />
                      IELTS 8 o'qituvchilarning yordami (haftada 2 marta).
                    </li>
                    <li>
                      <img src="/homePage/checked2.png" alt="img" />
                      Spaking club IELTS 8 ustoz ishtirokida.
                    </li>
                    <li>
                      <img src="/homePage/checked2.png" alt="img" />
                      Perfectly spoken platform 2 oy.
                    </li>
                    <li>
                      <img src="/homePage/checked2.png" alt="img" />
                      Mr. Adrian bilan Speaking (1 oyda bir).
                    </li>
                    <li>
                      <img src="/homePage/xchecked2.png" alt="img" />
                      <del>Essay checking (6 marta). </del>
                    </li>
                    <li>
                      <Button onClick={()=>{openModal()}}>SOTIB OLISH</Button>
                    </li>
                  </ul>
                {/* </Link> */}
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className={s.prices__card} id="VIP" onClick={Click}>
                {/* <Link to="/paymePage"> */}
                  <div className={s.prices__top}>
                    <h1>VIP</h1>
                    <p>Yuqori marrani ko'zlaganlarga mos</p>
                    <h5>
                      <span>2.299.000</span>/Bir yillik
                    </h5>
                  </div>
                  <ul className={s.prices__bottom}>
                    <li>
                      <img src="/homePage/checked2.png" alt="img" />
                      Platforma (1 yil).
                    </li>
                    <li>
                      <img src="/homePage/checked2.png" alt="img" />
                      Doimiy qo'llab quvvatlash.
                    </li>
                    <li>
                      <img src="/homePage/checked2.png" alt="img" />
                      Haftada 1 marta NS bilan zoomda muloqot.
                    </li>
                    <li>
                      <img src="/homePage/checked2.png" alt="img" />
                      Kurator bilan dars (haftada 1 marta).
                    </li>
                    <li>
                      <img src="/homePage/checked2.png" alt="img" />
                      IELTS 8 o'qituvchilarning yordami (haftada 2 marta).
                    </li>
                    <li>
                      <img src="/homePage/checked2.png" alt="img" />
                      Spaking club IELTS 8 ustoz ishtirokida.
                    </li>
                    <li>
                      <img src="/homePage/checked2.png" alt="img" />
                      Perfectly spoken platform 2 oy.
                    </li>
                    <li>
                      <img src="/homePage/checked2.png" alt="img" />
                      Mr. Adrian bilan Speaking (1 oyda bir).
                    </li>
                    <li>
                      <img src="/homePage/checked2.png" alt="img" />
                      Essay checking (6 marta).
                    </li>
                    <li>
                      <Button onClick={()=>{openModal()}}>SOTIB OLISH</Button>
                    </li>
                  </ul>
                {/* </Link> */}
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className={s.prices__card} id="Comfort" onClick={Click}>
                {/* <Link to="/paymePage"> */}
                  <div className={s.prices__top}>
                    <h1>Comfort (oyma-oy)</h1>
                    <p>Oyma-oy to'lab o'rganish</p>
                    <h5>
                      <span>699.000</span>/Bir oylik
                    </h5>
                  </div>
                  <ul className={s.prices__bottom}>
                    <li>
                      <img src="/homePage/checked2.png" alt="img" />
                      Platforma.
                    </li>
                    <li>
                      <img src="/homePage/checked2.png" alt="img" />
                      Doimiy qo'llab quvvatlash.
                    </li>
                    <li>
                      <img src="/homePage/checked2.png" alt="img" />
                      Haftada 1 marta NS bilan zoomda muloqot.
                    </li>
                    <li>
                      <img src="/homePage/checked2.png" alt="img" />
                      Kurator bilan dars (haftada 1 marta).
                    </li>
                    <li>
                      <img src="/homePage/checked2.png" alt="img" />
                      Bepul mock testlar.
                    </li>
                    <li>
                      <img src="/homePage/checked2.png" alt="img" />
                      Mr. Adrian bilan Speaking (1 oyda bir).
                    </li>
                    <li>
                      <Button onClick={()=>{openModal()}}>SOTIB OLISH</Button>
                    </li>
                  </ul>
                {/* </Link> */}
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      ) : null}

            {/* modal */}
            <AntdModal
        open={isModalOpen}
        footer={null}
            // closable={false}
            onCancel={()=>{
              setIsModelOpen(false)
            }}
          >
            <p><strong style={{fontSize: "20px"}}>Alaykum assalom, MyTeacherga xush kelibsiz! Iltimos ismingiz va telefon raqamingizni qoldiring. Siz bilan tez orada bog'lanamiz.</strong></p>
        <Form
          name="normal_login"
          className="login-form"
          onFinish={handleSubmit}
          form={formData}
        >
          <Form.Item
            key={"1"}
            name={"fullName"}
            label={"Ismingiz"}
            rules={[
              { required: true, message: "Iltimos ismingizni kiriting!" },
            ]}
          >
            <Input
              type={"text"}
              name={"fullName"}
              placeholder="Sizning ismingiz"
            />
          </Form.Item>
          <Form.Item
            key={"1"}
            name={"phone"}
            label={"Raqamingiz"}
            rules={[
              { required: true, message: "Iltimos raqmingizni kiriting!" },
            ]}
          >
            <Input
              prefix="+998"
              onChange={handleChange}
              maxLength={14}
              type={"tel"}
              name={"number"}
              placeholder="(__) ___ - __ - __"
            />
          </Form.Item>
          <Form.Item
            key={"1"}
            name={"message"}
            label={"Qo'shimcha"}
            // rules={[{ required: true, message: "Iltimos malumot kiriting!" }]}
          >
            <Input.TextArea
              rows={3}
              name={"Message"}
              placeholder="Izoh qoldirishingiz mumkin"
            />
          </Form.Item>
          <Form.Item valuePropName="checked">
          Ro'yxatdan o'tish bilan <a href="https://docs.google.com/document/d/1VZZqIF2vX_1D008AEvgHViZXZObIh7nNaPv0cSTTU0g/edit">
                  <span style={{color : "blue"}}> Ommaviy oferta va foydalanish shartlariga</span>
                  </a>{" "}
                  rozilik bildirasiz
              </Form.Item>
          <div className="flex items-center justify-end">
            <Button
              variant="secondary"
              onClick={cencelModal}
              size="sm"
              children="Yopish"
              style={{ margin: "0 10px" }}
            />
            <Button htmlType="submit" children="Jo'natish" size="sm" />
          </div>
        </Form>
      </AntdModal>
    </div>
  );
};

export default Prices;
