import "./style.scss";
import ShowCase from "../../components/ShowCase";
import { Title, Collapse, Button, SearchForm } from "../../components/Elements";
import { Container, Row, Col } from "react-bootstrap";

import { AiOutlineSchedule } from "react-icons/ai";
import BlogImg from "../../assets/sm.blog.png";
import { BiChevronRight } from "react-icons/bi";
import { AiOutlineUser } from "react-icons/ai";
import { GrSchedule } from "react-icons/gr";
import { BiMessageRounded } from "react-icons/bi";
import BigBlogImg from "../../assets/blogImg.png";
import { Outlet, useParams } from "react-router-dom";

const blogs = [1, 2, 3, 4, 5];
const categories = [1, 2, 3, 4, 5];
const recentPosts = [1, 2, 3, 4, 5];

export default function Blog() {
  const { id: blogId } = useParams();

  const blog = (_, idx) => (
    <div className="lg-blog-card" key={idx}>
      <div className="lg-blog-card__image">
        <img src={BigBlogImg} alt="blog" width="500" />
      </div>
      <div className="lg-blog-card__body">
        <div className="data">
          <div className="data__item name">
            <AiOutlineUser className="icon" /> <span>John Doe</span>
          </div>
          <div className="data__item date">
            <GrSchedule className="icon" /> <span>07 Jan, 2022</span>
          </div>
          <div className="data__item comment">
            <BiMessageRounded className="icon" /> <span>3 Comments</span>
          </div>
        </div>
        <div className="title">
          Class aptent taciti sociosqu ad litora torquent per conubia nostra,
          per inceptos himenaeos.
        </div>
        <div className="desc">
          Donec accumsan enim sit amet dolor rhoncus scelerisque. Suspendisse
          dictum, enim a interdum facilisis, ex diam dignissim ligula, sit amet
          commodo est nunc vulputate turpis.
        </div>
        <Button to="/blogs/diteils/sa78s8a5sasa4">READ MORE</Button>
      </div>
    </div>
  );

  
  return (
    <div className="blogs">
      <ShowCase pagination="ABOUT US" height="70vh">
        <Title line2="Our Blogs" textAlighCenter={true} />
      </ShowCase>
      <Container className="mt-5">
        <Row>
          <Col md={12} lg={8}>
            <div className="blogs-wrapper">
              {blogId ? <Outlet /> : blogs.map((_, idx) => blog(_, idx))}
            </div>
          </Col>
          <Col lg={4} md={12}>
            <SearchForm />
            <div className="blogs__box">
              <div className="blogs__box__title">Categories</div>
              <ul className="menu links-menu">
                {categories.map((_, idx) => (
                  <li className="menu__item" key={idx}>
                    <Collapse
                      toggle={
                        <>
                          <BiChevronRight className="menu-collapse__icon" />{" "}
                          Business(10)
                        </>
                      }
                    >
                      <span>body</span>
                    </Collapse>
                  </li>
                ))}
              </ul>
            </div>
            <div className="blogs__box">
              <div className="blogs__box__title">Recent Post</div>
              <ul className="menu blogs-menu">
                {recentPosts.map((_, idx) => (
                  <li className="menu__item" key={idx}>
                    <img
                      src={BlogImg}
                      className="blog-image"
                      alt="blog"
                      width="100"
                    />
                    <div>
                      <div className="title">
                        Mauris condimentum purus sit amet interdum.
                      </div>
                      <div className="date">
                        <AiOutlineSchedule className="icon" /> 0.7 Jan, 2022
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}
