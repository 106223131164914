import { Col, Container, Row } from "react-bootstrap";
import { Button, Title } from "../../components/Elements";
import ShowCase from "../../components/ShowCase";
import "./styles.scss";

import AvatarImg from "../../assets/sm.blog.png";
import EventImg from "../../assets/1090x500.png";
import Prices from "../../components/Prices";

export default function RusTili() {
  return (
    <div className="course-diteil">
      <ShowCase pagination="COURSE" height="70vh">
        <Title line2="Rus tili kursi" textAlighCenter={true} />
      </ShowCase>
      <div className="g-container">
        <Row>
          <Col md={8}>
            <Title line3="Angliyaning Perfectly Spoken platformasi (A2-C2)." />
            <div className="course-diteil__info flex items-center">
              <div className="info-item flex items-center">
                <img
                  src={AvatarImg}
                  alt="avatar"
                  className="course-diteil__info__avatar"
                  width="60"
                  height="60"
                />
                <div>
                  <div className="full-name data">Lillian Wals</div>
                  <span className="status">Instructor</span>
                </div>
              </div>
              <div className="info-item">
                <div className="date data">
                  <Button size="sm" children="RUS TILI" />
                </div>
              </div>
              <div className="info-item">
                <div className="location data">
                  <Button size="sm" variant="info" children="Individual" />
                </div>
              </div>
            </div>
            <img
              src={EventImg}
              alt="event"
              className="course-diteil__image"
              width="600"
            />
            <div className="course-diteil__title">Kurs haqida</div>
            <div className="vent-diteil__desc">
              Siz ushbu kursni darajangizdan qati nazar boshlashingiz va
              o'ingizga yetarli levelda yakunlashigiz mumkin <br/>Eng muhumi rus
              tilini haqiqiy rusiy zabon ustizlardan o'rganishigiz jarayoni 2
              barobar tezroq va sifatliroq bo'lishini taminlaydi. Ustozlarimiz
              bilan bo'ladigam zoom uchrashuvlarda siz nafaqat ustoz bilan balki
              guruhdoshlaringiz bilan ham yaqindan tanishib ular bilan ham
              razgavor qilish imkiniyatiga ega bo'lasiz
            </div>
          </Col>
          <Col md={4}>
          <div className="course-diteil__right">
            <div className="course-diteil__rightItem">
                <p>Get The Course</p>
                <ul className="course-diteil__list">
                  <li>
                    <img src="" alt="img" />
                    <span>
                      So'zlashuvmi Rivojalantiruvchi usullar
                    </span>
                  </li>
                  <li>
                    <img src="" alt="img" />
                    <span>
                      Lug'at Yodlash Texnikasi
                    </span>
                  </li>
                  <li>
                    <img src="" alt="img" />
                    <span>
                      Eshitib Tushunish
                    </span>
                  </li>
                </ul>
              </div>
              <div className="course-diteil__rightItem">
                <p>Related Courses</p>
                <ul className="course-diteil__list">
                  <li>
                    <img src="" alt="img" />
                    <span>
                      Mr. Adrian bilan zoom uchrashuv
                    </span>
                  </li>
                  <li>
                    <img src="" alt="img" />
                    <span>
                      Samantha bilan jonli zoom uchrashuv
                    </span>
                  </li>
                  <li>
                    <img src="" alt="img" />
                    <span>
                      Essay checking ($3.5)
                    </span>
                  </li>
                  <li>
                    <img src="" alt="img" />
                    <span>
                      Speaking course with native speaker
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </Col>
        </Row>
      </div>
      <Container style={{marginTop: "50px"}}>
        <Prices />
      </Container>
    </div>
  );
}
