import { Col, Row } from 'react-bootstrap'
import './styles.scss'

import BlogImg from '../../assets/1090x500.png'
import BgImg from '../../assets/sm.blog.png'

import {BiUser} from 'react-icons/bi'
import {AiOutlineSchedule, AiOutlineInstagram} from 'react-icons/ai'
import {FaRegComment} from 'react-icons/fa'
import {GrFacebookOption, GrTwitter} from 'react-icons/gr'
import {BsYoutube, BsReplyFill} from 'react-icons/bs'
import { Button, Title } from '../Elements'
import BlogCard from '../BlogCard'

const blogs = [1,2,3,4,5]
const comments = [1,2]

export default function BlogDiteils() {
  return (
    <>
        <div className="blog-diteils">
            <img src={BlogImg} alt="" className="blog-diteils__img w-full" width='500' />
            <ul className="blog-diteils__info flex items-center">
                <li>
                    <BiUser className='icon' />
                    <span className='text'>John Doe</span>
                </li>
                <li>
                    <AiOutlineSchedule className='icon' />
                    <span className='text'>07 Jan, 2022</span>
                </li>
                <li>
                    <FaRegComment className='icon' />
                    <span className='text'>3 Comments</span>
                </li>
            </ul>
            <div className="blog-diteils__title">Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos.</div>
            <div className="blog-diteils__desc">Donec sit amet eros non massa vehicula porta. Nulla facilisi. Suspendisse ac aliquet nisi, lacinia mattis magna. Praesent quis consectetur neque, sed viverra neque. Mauris ultrices massa purus, fermentum ornare magna gravida vitae. Nulla sit amet est a enim porta gravida. Integer consectetur diam vitae imperdiet iaculis. In faucibus, sem sit amet tincidunt egestas, magna ligula interdum leo, quis lacinia mauris odio nec lectus.</div>
            <div className="blog-diteils__title">Educal is the only theme you will ever need</div>
            <ul className="adventages-menu">
                <li className="adventages-menu__item">Suspendisse nunc massa, pellentesque eu nibh eget.</li>
                <li className="adventages-menu__item">Suspendisse nunc massa, pellentesque eu nibh eget.</li>
                <li className="adventages-menu__item">Suspendisse nunc massa, pellentesque eu nibh eget.</li>
            </ul>
            <div className="remember-box">
                <div className="content">Integer eu lorem augue. Curabitur luctus, orci in dictum accumsan, nulla enim aliquam risus.</div>
                <div className="author">William Son</div>
            </div>
            <div className="blog-diteils__desc">Donec sit amet eros non massa vehicula porta. Nulla facilisi. Suspendisse ac aliquet nisi, lacinia mattis magna. Praesent quis consectetur neque, sed viverra neque. Mauris ultrices massa purus, fermentum ornare magna gravida vitae. Nulla sit amet est a enim porta gravida</div>
            <div className='blog-diteils__action flex items-center justify-between'>
                <div className="tags flex items-center">
                    <b>Tags:</b>
                    <div className="tag-button">Corporate</div>
                    <div className="tag-button">Blog</div>
                    <div className="tag-button">Marketing</div>
                </div>
                <div className="socials flex items-center">
                    <a href="/#">
                        <GrFacebookOption className='icon' />
                    </a>
                    <a href="/#">
                        <AiOutlineInstagram className='icon' />
                    </a>
                    <a href="/#">
                        <GrTwitter className='icon' />
                    </a>
                    <a href="/#">
                        <BsYoutube className='icon' />
                    </a>
                </div>
            </div>
            <Title line1='OUR POST' line3='Related Blog' />
            <div className="blogs-wrapper">
                <Row>
                    {blogs.map((_, idx) => (
                        <Col md={6} key={idx}>
                            <BlogCard />    
                        </Col>
                    ))}
                </Row>  
            </div>
            <div className="comments-wrapper">
                <Title line1='OUR REVIEWS' line3='COMMENTS' />
                <div>
                    {comments.map((_, idx) => (
                        <div className="comment" key={idx}>
                            <img src={BgImg} alt="user acvatar" className="comment__img" />
                            <div>
                                <div className="full-name">Lillian Walsh</div>
                                <div className="date">07 March, 2021</div>
                                <div className="desc">Integer consectetur diam vitae imperdiet iaculis. In faucibus, sem sit amet tincidunt egestas, magna ligula interdum leo.</div>
                                <Button size='sm' icon={<BsReplyFill />}>REPLY</Button>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <div>
                <Title line1='WRITE COMMENT' line3='Leave A Reply' />
                <form className="comment-form">
                    <Row>
                        <Col md={4}>
                            <input className='comment-form__input' type="text" placeholder='First Name' name='firstName' />
                        </Col>
                        <Col md={4}>
                            <input className='comment-form__input' type="text" placeholder='Last Name' name='lastName' />
                        </Col>
                        <Col md={4}>
                            <input className='comment-form__input' type="text" placeholder='Email' name='email' />
                        </Col>
                        <Col md={12}>
                            <textarea className='comment-form__textarea' placeholder='Message' name='message'></textarea>
                        </Col>
                    </Row>
                    <Button>POST NOW</Button>
                </form>
            </div>
        </div>
    </>
  )
}
