export const testData2 = [
  {
    key: 1,
    question:
      'Choose the correct form of the verb "to be" for the pronoun "I":',
    options: [
      {
        key: 1,
        value: "am",
      },
      {
        key: 2,
        value: "is",
      },
      {
        key: 3,
        value: "are",
      },
    ],
    currect: {
      key: 1,
      value: "am",
    },
  },
  {
    key: 2,
    question: 'Complete the sentence: "She _ a student."',
    options: [
      {
        key: 1,
        value: "am",
      },
      {
        key: 2,
        value: "is",
      },
      {
        key: 3,
        value: "are",
      },
    ],
    currect: {
      key: 2,
      value: "is",
    },
  },
  {
    key: 3,
    question:
      'Choose the correct form of the verb: "They _ to school every day."',
    options: [
      {
        key: 1,
        value: "go",
      },
      {
        key: 2,
        value: "goes",
      },
      {
        key: 3,
        value: "went",
      },
    ],
    currect: {
      key: 1,
      value: "go",
    },
  },
  {
    key: 4,
    question: 'Complete the sentence: "He _ football yesterday."',
    options: [
      {
        key: 1,
        value: "play",
      },
      {
        key: 2,
        value: "played",
      },
      {
        key: 3,
        value: "plays",
      },
    ],
    currect: {
      key: 2,
      value: "played",
    },
  },
  {
    key: 5,
    question: 'Choose the correct word: "I enjoy _ books in my free time."',
    options: [
      {
        key: 1,
        value: "read",
      },
      {
        key: 2,
        value: "reads",
      },
      {
        key: 3,
        value: "reading",
      },
    ],
    currect: {
      key: 3,
      value: "reading",
    },
  },
  {
    key: 6,
    question: 'Choose the correct past tense of the verb "to swim":',
    options: [
      {
        key: 1,
        value: "swim",
      },
      {
        key: 2,
        value: "swam",
      },
      {
        key: 3,
        value: "swum",
      },
    ],
    currect: {
      key: 2,
      value: "swam",
    },
  },
  {
    key: 7,
    question: 'Choose the correct question form: "_ you like ice cream?"',
    options: [
      {
        key: 1,
        value: "Do",
      },
      {
        key: 2,
        value: "Does",
      },
      {
        key: 3,
        value: "Did",
      },
    ],
    currect: {
      key: 1,
      value: "Do",
    },
  },
  {
    key: 8,
    question: `Complete the sentence with the correct preposition: "I'm going _ vacation next week."`,
    options: [
      {
        key: 1,
        value: "on",
      },
      {
        key: 2,
        value: "at",
      },
      {
        key: 3,
        value: "in",
      },
    ],
    currect: {
      key: 1,
      value: "on",
    },
  },
  {
    key: 9,
    question: 'Choose the correct form of the verb: "We _ English every day."',
    options: [
      {
        key: 1,
        value: "study",
      },
      {
        key: 2,
        value: "studies",
      },
      {
        key: 3,
        value: "studied",
      },
    ],
    currect: {
      key: 1,
      value: "study",
    },
  },
  {
    key: 10,
    question:
      'Choose the correct word to complete the sentence: "She is _ doctor."',
    options: [
      {
        key: 1,
        value: "a",
      },
      {
        key: 2,
        value: "an",
      },
      {
        key: 3,
        value: "the",
      },
    ],
    currect: {
      key: 1,
      value: "a",
    },
  },
  {
    key: 11,
    question: 'Choose the correct modal verb: "I _ play the guitar."',
    options: [
      {
        key: 1,
        value: "can",
      },
      {
        key: 2,
        value: "could",
      },
      {
        key: 3,
        value: "will",
      },
    ],
    currect: {
      key: 1,
      value: "can",
    },
  },
  {
    key: 12,
    question:
      'Choose the correct form of the verb: "She _ English for five years."',
    options: [
      {
        key: 1,
        value: "study",
      },
      {
        key: 2,
        value: "studies",
      },
      {
        key: 3,
        value: "has studied",
      },
    ],
    currect: {
      key: 3,
      value: "has studied",
    },
  },
  {
    key: 13,
    question: `Complete the sentence with the correct preposition: "I'm interested _ learning new languages."`,
    options: [
      {
        key: 1,
        value: "in",
      },
      {
        key: 2,
        value: "on",
      },
      {
        key: 3,
        value: "at",
      },
    ],
    currect: {
      key: 1,
      value: "in",
    },
  },
  {
    key: 14,
    question: 'Choose the correct question tag: "He is a doctor, _?"',
    options: [
      {
        key: 1,
        value: "isn't he?",
      },
      {
        key: 2,
        value: "doesn't he",
      },
      {
        key: 3,
        value: "is he?",
      },
    ],
    currect: {
      key: 1,
      value: "isn't he?",
    },
  },
  {
    key: 15,
    question:
      'Choose the correct word to complete the sentence: "She speaks English _ than her brother."',
    options: [
      {
        key: 1,
        value: "better",
      },
      {
        key: 2,
        value: "best",
      },
      {
        key: 3,
        value: "good",
      },
    ],
    currect: {
      key: 1,
      value: "better",
    },
  },
  {
    key: 16,
    question:
      'Choose the correct phrasal verb: "I need to _ the meeting because of a conflict."',
    options: [
      {
        key: 1,
        value: "cancel",
      },
      {
        key: 2,
        value: "put off",
      },
      {
        key: 3,
        value: "take off",
      },
    ],
    currect: {
      key: 2,
      value: "put off",
    },
  },
  {
    key: 17,
    question:
      'Choose the correct word to complete the sentence: "I wish I _ more free time."',
    options: [
      {
        key: 1,
        value: "had",
      },
      {
        key: 2,
        value: "have",
      },
      {
        key: 3,
        value: "has",
      },
    ],
    currect: {
      key: 1,
      value: "had",
    },
  },
  {
    key: 18,
    question:
      'Choose the correct relative pronoun: "The girl _ won the race was very talented."',
    options: [
      {
        key: 1,
        value: "who",
      },
      {
        key: 2,
        value: "whom",
      },
      {
        key: 3,
        value: "which",
      },
    ],
    currect: {
      key: 1,
      value: "who",
    },
  },
  {
    key: 19,
    question:
      'Choose the correct word order for the sentence: "Yesterday, _ to the cinema with my friends."',
    options: [
      {
        key: 1,
        value: "I went",
      },
      {
        key: 2,
        value: "went I",
      },
      {
        key: 3,
        value: "did I go",
      },
    ],
    currect: {
      key: 1,
      value: "I went",
    },
  },
  {
    key: 20,
    question:
      'Choose the correct tense: "I _ studying English for three years."',
    options: [
      {
        key: 1,
        value: "have been",
      },
      {
        key: 2,
        value: "am",
      },
      {
        key: 3,
        value: "was",
      },
    ],
    currect: {
      key: 1,
      value: "have been",
    },
  },
];

export const testData = [
  {
    key: 1,
    question: "We ___ American.",
    options: [
      {
        key: 1,
        value: "not",
      },
      {
        key: 2,
        value: "not are",
      },
      {
        key: 3,
        value: "aren’t",
      },
      {
        key: 4,
        value: "isn’t",
      },
    ],
    currect: {
      key: 3,
      value: "aren't",
    },
  },
  {
    key: 2,
    question: "He ___ the newspaper every day.",
    options: [
      {
        key: 1,
        value: "read",
      },
      {
        key: 2,
        value: "reads",
      },
      {
        key: 3,
        value: "doesn’t reads ",
      },
      {
        key: 4,
        value: "don’t reads",
      },
    ],
    currect: {
      key: 2,
      value: "reads",
    },
  },
  {
    key: 3,
    question: "___ you like Chinese food?",
    options: [
      {
        key: 1,
        value: "Do",
      },
      {
        key: 2,
        value: "Does",
      },
      {
        key: 3,
        value: "Are",
      },
      {
        key: 4,
        value: "Is",
      },
    ],
    currect: {
      key: 1,
      value: "Do",
    },
  },
  {
    key: 4,
    question: "It’s ten ___ seven",
    options: [
      {
        key: 1,
        value: "to",
      },
      {
        key: 2,
        value: "for",
      },
      {
        key: 3,
        value: "at",
      },
      {
        key: 4,
        value: "in",
      },
    ],
    currect: {
      key: 1,
      value: "to",
    },
  },
  {
    key: 5,
    question: "I haven’t ___ this photo before.",
    options: [
      {
        key: 1,
        value: "see",
      },
      {
        key: 2,
        value: "saw",
      },
      {
        key: 3,
        value: "to see",
      },
      {
        key: 4,
        value: "seen",
      },
    ],
    currect: {
      key: 4,
      value: "seen",
    },
  },
  {
    key: 6,
    question: "‘Was Debussy from France?’ ‘Yes, ___.’",
    options: [
      {
        key: 1,
        value: "he were",
      },
      {
        key: 2,
        value: "was",
      },
      {
        key: 3,
        value: "there were",
      },
      {
        key: 4,
        value: "he was",
      },
    ],
    currect: {
      key: 4,
      value: "he was",
    },
  },
  {
    key: 7,
    question: "Can I pay ___ credit card?",
    options: [
      {
        key: 1,
        value: "by",
      },
      {
        key: 2,
        value: "in",
      },
      {
        key: 3,
        value: "on",
      },
      {
        key: 4,
        value: "with",
      },
    ],
    currect: {
      key: 4,
      value: "with",
    },
  },
  {
    key: 8,
    question: "They didn’t ___ the tickets.",
    options: [
      {
        key: 1,
        value: "booking",
      },
      {
        key: 2,
        value: "booked",
      },
      {
        key: 3,
        value: "to book",
      },
      {
        key: 4,
        value: "book",
      },
    ],
    currect: {
      key: 2,
      value: "booked",
    },
  },
  {
    key: 9,
    question: "She ___ to the gym every day.",
    options: [
      {
        key: 1,
        value: "gets",
      },
      {
        key: 2,
        value: "goes",
      },
      {
        key: 3,
        value: "has",
      },
      {
        key: 4,
        value: "does",
      },
    ],
    currect: {
      key: 2,
      value: "goes",
    },
  },
  {
    key: 10,
    question: "He ___ playing the piano.",
    options: [
      {
        key: 1,
        value: "are",
      },
      {
        key: 2,
        value: "does",
      },
      {
        key: 3,
        value: "is",
      },
      {
        key: 4,
        value: "has",
      },
    ],
    currect: {
      key: 3,
      value: "is",
    },
  },
  {
    key: 11,
    question: "It ___ when they went out.",
    options: [
      {
        key: 1,
        value: "rained",
      },
      {
        key: 2,
        value: "was raining",
      },
      {
        key: 3,
        value: "is raining",
      },
      {
        key: 4,
        value: "was to rain",
      },
    ],
    currect: {
      key: 2,
      value: "was raining",
    },
  },
  {
    key: 12,
    question: "Diana ___ some wine when she went to France.",
    options: [
      {
        key: 1,
        value: "bought",
      },
      {
        key: 2,
        value: "buyed",
      },
      {
        key: 3,
        value: "boot",
      },
      {
        key: 4,
        value: "did buy",
      },
    ],
    currect: {
      key: 1,
      value: "bought",
    },
  },
  {
    key: 13,
    question: "___ I worked hard, I didn’t pass the test.",
    options: [
      {
        key: 1,
        value: "Although",
      },
      {
        key: 2,
        value: "So",
      },
      {
        key: 3,
        value: "Because",
      },
      {
        key: 4,
        value: "But",
      },
    ],
    currect: {
      key: 1,
      value: "Although",
    },
  },
  {
    key: 14,
    question: "Who ___ the answer to this question?",
    options: [
      {
        key: 1,
        value: "knows",
      },
      {
        key: 2,
        value: "know",
      },
      {
        key: 3,
        value: "does know",
      },
      {
        key: 4,
        value: "does knows",
      },
    ],
    currect: {
      key: 1,
      value: "knows",
    },
  },
  {
    key: 15,
    question: "I can sing, but not as ___ as my sister.",
    options: [
      {
        key: 1,
        value: "well",
      },
      {
        key: 2,
        value: "good",
      },
      {
        key: 3,
        value: "best",
      },
      {
        key: 4,
        value: "better",
      },
    ],
    currect: {
      key: 1,
      value: "well",
    },
  },
  {
    key: 16,
    question: "It’s important ___ too much alcohol.",
    options: [
      {
        key: 1,
        value: "not to drinking",
      },
      {
        key: 2,
        value: "not to drink",
      },
      {
        key: 3,
        value: "not drink",
      },
      {
        key: 4,
        value: "not drinks",
      },
    ],
    currect: {
      key: 2,
      value: "not to drink",
    },
  },
  {
    key: 17,
    question: "Pam ___ eat cheese, but she does now.",
    options: [
      {
        key: 1,
        value: "didn’t used to",
      },
      {
        key: 2,
        value: "did use to",
      },
      {
        key: 3,
        value: "didn’t use to",
      },
      {
        key: 4,
        value: "wasn’t to",
      },
    ],
    currect: {
      key: 3,
      value: "didn’t use to",
    },
  },
  {
    key: 18,
    question: "___ my best friend since 1999.",
    options: [
      {
        key: 1,
        value: "I’ve known",
      },
      {
        key: 2,
        value: "I knew",
      },
      {
        key: 3,
        value: "I’m knowing",
      },
      {
        key: 4,
        value: "I know",
      },
    ],
    currect: {
      key: 1,
      value: "I’ve known",
    },
  },
  {
    key: 19,
    question: "Michelangelo ___ some of his best works in Rome.",
    options: [
      {
        key: 1,
        value: "painted",
      },
      {
        key: 2,
        value: "was painted",
      },
      {
        key: 3,
        value: "is painting",
      },
      {
        key: 4,
        value: "has painted",
      },
    ],
    currect: {
      key: 1,
      value: "painted",
    },
  },
  {
    key: 20,
    question: "I’m really tired – I only got ___ hours’ sleep.",
    options: [
      {
        key: 1,
        value: "not many",
      },
      {
        key: 2,
        value: "a few",
      },
      {
        key: 3,
        value: "a little",
      },
      {
        key: 4,
        value: "few",
      },
    ],
    currect: {
      key: 2,
      value: "a few",
    },
  },
  {
    key: 21,
    question: "___ Kate nor I want to go to London.",
    options: [
      {
        key: 1,
        value: "Neither",
      },
      {
        key: 2,
        value: "Both",
      },
      {
        key: 3,
        value: "Either",
      },
      {
        key: 4,
        value: "Not",
      },
    ],
    currect: {
      key: 1,
      value: "Neither",
    },
  },
  {
    key: 22,
    question: "This food isn’t ___.",
    options: [
      {
        key: 1,
        value: "enough hot",
      },
      {
        key: 2,
        value: "hot enough",
      },
      {
        key: 3,
        value: "much hot",
      },
      {
        key: 4,
        value: "very much hot",
      },
    ],
    currect: {
      key: 2,
      value: "hot enough",
    },
  },
  {
    key: 23,
    question: "When is it going to stop ___?",
    options: [
      {
        key: 1,
        value: "to rain",
      },
      {
        key: 2,
        value: "rain",
      },
      {
        key: 3,
        value: "to raining",
      },
      {
        key: 4,
        value: "raining",
      },
    ],
    currect: {
      key: 4,
      value: "raining",
    },
  },
  {
    key: 24,
    question: "She doesn’t go to the gym ___.",
    options: [
      {
        key: 1,
        value: "no more",
      },
      {
        key: 2,
        value: "no longer",
      },
      {
        key: 3,
        value: "more",
      },
      {
        key: 4,
        value: "any more",
      },
    ],
    currect: {
      key: 4,
      value: "any more",
    },
  },
  {
    key: 25,
    question: "Would you marry him if he ___ you?",
    options: [
      {
        key: 1,
        value: "would ask",
      },
      {
        key: 2,
        value: "asks",
      },
      {
        key: 3,
        value: "did ask",
      },
      {
        key: 4,
        value: "asked",
      },
    ],
    currect: {
      key: 4,
      value: "asked",
    },
  },
  {
    key: 26,
    question: "Can I ___ cheque?",
    options: [
      {
        key: 1,
        value: "pay by",
      },
      {
        key: 2,
        value: "pay with",
      },
      {
        key: 3,
        value: "pay in",
      },
      {
        key: 4,
        value: "pay on",
      },
    ],
    currect: {
      key: 2,
      value: "pay with",
    },
  },
  {
    key: 27,
    question: "I ___ go to the dentist yesterday.",
    options: [
      {
        key: 1,
        value: "must",
      },
      {
        key: 2,
        value: "musted",
      },
      {
        key: 3,
        value: "had to",
      },
      {
        key: 4,
        value: "have to",
      },
    ],
    currect: {
      key: 3,
      value: "had to",
    },
  },
  {
    key: 28,
    question: "I refused ___ to them.",
    options: [
      {
        key: 1,
        value: "to talk",
      },
      {
        key: 2,
        value: "talk",
      },
      {
        key: 3,
        value: "to talking",
      },
      {
        key: 4,
        value: "talking",
      },
    ],
    currect: {
      key: 1,
      value: "to talk",
    },
  },
  {
    key: 29,
    question: "He works too hard so it’s not ___ he’s ill.",
    options: [
      {
        key: 1,
        value: "surprise",
      },
      {
        key: 2,
        value: "to surprise",
      },
      {
        key: 3,
        value: "surprising",
      },
      {
        key: 4,
        value: "surprised",
      },
    ],
    currect: {
      key: 1,
      value: "surprise",
    },
  },
  {
    key: 30,
    question: "They don’t get ___ very well.",
    options: [
      {
        key: 1,
        value: "together",
      },
      {
        key: 2,
        value: "on",
      },
      {
        key: 3,
        value: "in",
      },
      {
        key: 4,
        value: "by",
      },
    ],
    currect: {
      key: 1,
      value: "together",
    },
  },
];
