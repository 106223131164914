import './styles.scss'

import {AiOutlineSearch} from 'react-icons/ai'

export default function SearchForm() {
  return (
    <form className='search-form'>
        <input type="text" placeholder='Search...'/>
        <button type='submit'><AiOutlineSearch /></button>
    </form>
  )
}
