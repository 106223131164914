import { Col, Container, Row } from "react-bootstrap";
import { Button, Title } from "../../components/Elements";
import ShowCase from "../../components/ShowCase";
import "./styles.scss";

import AvatarImg from "../../assets/sm.blog.png";
import EventImg from "../../assets/1090x500.png";
import Prices from "../../components/Prices";

export default function PerfSpoken() {
  return (
    <div className="course-diteil">
      <ShowCase pagination="COURSE" height="70vh">
        <Title line2="General English kursi" textAlighCenter={true} />
      </ShowCase>
      <div className="g-container">
        <Row>
          <Col md={8}>
            <Title line3="Angliyalik hamkorlarimiz tomonidan yaratilgan General English kursi" />
            <div className="course-diteil__info flex items-center">
              <div className="info-item flex items-center">
                <img
                  src="/courseCard/samantha.jpg"
                  alt="avatar"
                  className="course-diteil__info__avatar"
                  width="60"
                  height="60"
                />
                <div>
                  <div className="full-name data">Samantha du Ploy</div>
                  <span className="status">O'qituvchi</span>
                </div>
              </div>
              <div className="info-item">
                <div className="date data">
                  <Button size="sm" children="CEFR" />
                </div>
              </div>
              <div className="info-item">
                <div className="location data">
                  <Button size="sm" variant="info" children="Native Sp" />
                </div>
              </div>
            </div>
            <img
              src="/courseCard/samanthaCourse.jpg"
              alt="event"
              className="course-diteil__image"
              width="600"
            />
            <div className="course-diteil__title">Kurs haqida</div>
            <div className="vent-diteil__desc">
              Sizga platformamizdan shaxsiy kabinet ochiladi, O'sha shaxsiy
              kabinetingizda to'liq darslar joylashgan bo'ladi. Darslar
              video+audio+sinov testi+tekst va mashqlar shaklida bo'lib, eng
              oson mavzudan murakkabiga qarab boraveradi. Masalan, siz bitta
              mavzudagi videoni ko'rdingiz, undan keyin shu mavzu bo'yicha mashq
              bajarasiz hamda testini yechasiz. Agar o'tolmasangiz yana qaytadan
              bajarasiz va shu sababdan hammasini mukammal o'rganib olishga
              majbur bo'lasiz. Bundan tashqari, sizga alohida kurator ham
              biriktiriladi va bu kurator sizdan o’rganishni doimiy talab qilib
              turadi hamda haftada bir yoki bir necha marta shu kursimizga
              yozilgan o'quvchilar uchun zoom orqali onlayn dars o’tib beradi,
              bu zoom darslarida 6 oy davomida kuratoringiz siz bilan lug’atlar,
              listening va speaking mashg’ulotlarini o’tkazib boradi hamda
              qanday o'rganayotganligingizni qattiq nazorat qilib borishadi.
            </div>
          </Col>
          <Col md={4}>
            <div className="course-diteil__right">
              <div className="course-diteil__rightItem">
                <p>Kurs o'z ichiga oladi</p>
                <ul className="course-diteil__list">
                  <li>
                    <img src="/homePage/checked2.png" alt="img" />
                    <span>Video darslar</span>
                  </li>
                  <li>
                    <img src="/homePage/checked2.png" alt="img" />
                    <span>Samantha bilan jonli zoom uchrashuv</span>
                  </li>
                  <li>
                    <img src="/homePage/checked2.png" alt="img" />
                    <span>Sinov testlari</span>
                  </li>
                  <li>
                    <img src="/homePage/checked2.png" alt="img" />
                    <span>Speaking course with native speaker</span>
                  </li>
                </ul>
              </div>
            </div>
          </Col>
        </Row>
      </div>
      {/* <Container style={{ marginTop: "50px" }}>
        <Prices course="PerfSpoken" />
      </Container> */}
    </div>
  );
}
