import { createStore } from "redux";
import { ECONOM, COMFORT, STANDART, VIP, PREMIUM } from "./actionType";

const initialState = {
  selectedPlan: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case ECONOM:
      return { ...state, selectedPlan: "Econom" };
    case STANDART:
      return { ...state, selectedPlan: "Standart" };
    case PREMIUM:
      return { ...state, selectedPlan: "Premium" };
    case VIP:
      return { ...state, selectedPlan: "VIP" };
    case COMFORT:
      return { ...state, selectedPlan: "Comfort" };
    default:
      return state;
  }
};


export const store = createStore(reducer)