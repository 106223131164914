import './styles.scss'
import { Button } from '../Elements'

export default function PriceBox() {
  return (
    <div class="box">
        <div class="box__type">Econom</div>
        <div class="box__desc">Faqat mustaqil o'rganishni istovchilarga mos</div>
        <hr class="divider" />
        <div class="box__price"><span>59$</span> / Month</div>
        <ul class="advantages">
            <li className='advantages__item'>To'liq VIP platforma</li>
            <li className='advantages__item'>Mock test</li>
            <li className='advantages__item'>Partner speaking</li>
            <li className='advantages__item'>Tizimlashtirilgan video darslar</li>
            <li className='advantages__item'>Qo'shimcha imkoniyatlar</li>
        </ul>
        <Button className='box__button'>CHOOSE PLAN</Button>
    </div>
  )
}
