import React, { useState } from "react";
import { GrFacebookOption } from "react-icons/gr";
import {
  AiOutlineInstagram,
  AiFillYoutube,
} from "react-icons/ai";
import { FaTelegramPlane } from "react-icons/fa";
import Button from "../Elements/Button/Button";
import s from "./style.module.scss";
import { Link } from "react-router-dom";
import { Modal as AntdModal, Input, message } from "antd";
import Form from "antd/es/form/Form";
import axios from "axios";

const Footer = () => {
  // Modal
  const [formData] = Form.useForm();
  // const [loading, setLoading] = useState(false);
  const [isModalOpen, setIsModelOpen] = useState(false);


  const handleChange = (e) => {
    const { value } = e.target;
    const formattedValue = formatPhoneNumber(value);
    formData.setFieldValue("phone", formattedValue);
  };

  const formatPhoneNumber = (value) => {
    // Удаляем все нецифровые символы из введенного значения
    const cleanedValue = value.replace(/\D/g, "");

    // Форматируем номер в требуемый формат
    let formattedValue = cleanedValue;
    if (cleanedValue.length > 2) {
      formattedValue = cleanedValue.replace(
        /(\d{2})(\d{3})(\d{2})(\d{2})/,
        "($1) $2-$3-$4"
      );
    } else if (cleanedValue.length > 0) {
      formattedValue = cleanedValue.replace(/(\d{1,2})/, "($1");
    }

    return formattedValue;
  };
  const cencelModal = () => {
    formData.resetFields();
    setIsModelOpen(false);
  };
  const openModal = () => {
    setIsModelOpen(true);
  };

  const handleSubmit = async (data) => {
    try {
      // send to server
      const resp = axios.post("https://myteacher.uz/api/register", {
        name: data.fullName,
        phone: `998${data.phone
          ?.replaceAll(" ", "")
          .replaceAll("-", "")
          .replace("(", "")
          .replace(")", "")}`,
        comment: data.message,
      });
      resp.then((e) => {
        console.log(e);
      });
    } catch (e) {
      // show notify
      message.error("Something went wrong!");
    } finally {
      setIsModelOpen(false);
    }
  };
  return (
    <footer className={s.footer}>
      <div className={`${s.footer__box} g-container`}>
        <div className={s.footer__top}>
          {/* title */}
          <h3>Maroq bilan Onlayn til o'rganishni Hozirdan boshlang!</h3>
          <Button onClick={openModal} children="BOSHLASH" />
        </div>
        <div className={s.footer__info}>
          <span>"MYTEACHER GROUP" MCHJ</span>
          <a href="tel: +998785559637">Tel: (78) 555 - 96 - 37</a>
          <div className={s.footer__icons}>
            <a href="https://www.facebook.com/myteacher.uz/" target="_blank" rel="noreferrer">
              <i>
                {" "}
                <GrFacebookOption />
              </i>
            </a>
            <a href="https://www.instagram.com/myteacher.uz/" target="_blank" rel="noreferrer">
              <i>
                <AiOutlineInstagram />
              </i>
            </a>
            <a href="https://t.me/Myteacher_IELTS" target="_blank" rel="noreferrer">
              <i>
                {" "}
                <FaTelegramPlane />{" "}
              </i>
            </a>
            <a href="https://www.youtube.com/@myteacheruz" target="_blank" rel="noreferrer">
              <i>
                {" "}
                <AiFillYoutube />
              </i>
            </a>
          </div>
        </div>
        <ul className={s.footer__list}>
          <li>Bizning Linklar</li>
          <li>
            <Link to="/">Home</Link>
          </li>
          <li>
            <Link to="/about">Biz haqimizda</Link>
          </li>
          <li>
            <Link to="/courses">Kurslarimiz</Link>
          </li>
          <li>
            <Link to="/blog">Blog</Link>
          </li>
          <li>
            <Link to="/contact">Bog'lanish</Link>
          </li>
        </ul>
        <ul className={s.footer__list}>
          <li>Boshqa Linklar</li>
          <li>
            <Link to="/instuctors">Instuktorlar</Link>
          </li>
          <li>
            <Link to="/faq">FAQ</Link>
          </li>
          <li>
            <Link to="/event">Eventlar</Link>
          </li>
          <li>
            <Link to="/policy">Privacy Policy</Link>
          </li>
          <li>
            <Link to="/team">Bizning Jamoa</Link>
          </li>
        </ul>
        <div className={s.footer__tg}>
          <p>Telegram kanalimizga obuna bo'lish esingizdan chiqmasin!</p>
          <a href="https://t.me/Myteacher_IELTS" target="_blank" rel="noreferrer">
            <p>OBUNA BO'LISH</p>
            <div>
              <FaTelegramPlane color="#fff" />
            </div>
          </a>
        </div>
      </div>

      {/* modal */}
      <AntdModal
        open={isModalOpen}
        footer={null}
            // closable={false}
            onCancel={()=>{
              setIsModelOpen(false)
            }}
          >
            <p>
          <strong style={{ fontSize: "20px" }}>
            Alaykum assalom, MyTeacherga xush kelibsiz! Iltimos ismingiz va
            telefon raqamingizni qoldiring. Siz bilan tez orada bog'lanamiz.
          </strong>
        </p>
        <Form
          name="normal_login"
          className="login-form"
          onFinish={handleSubmit}
          form={formData}
        >
          <Form.Item
            key={"1"}
            name={"fullName"}
            label={"Ismingiz"}
            rules={[
              { required: true, message: "Iltimos ismingizni kiriting!" },
            ]}
          >
            <Input
              type={"text"}
              name={"fullName"}
              placeholder="Sizning ismingiz"
            />
          </Form.Item>
          <Form.Item
            key={"1"}
            name={"phone"}
            label={"Raqamingiz"}
            rules={[
              { required: true, message: "Iltimos raqmingizni kiriting!" },
            ]}
          >
            <Input
              prefix="+998"
              onChange={handleChange}
              maxLength={14}
              type={"tel"}
              name={"number"}
              placeholder="(__) ___ - __ - __"
            />
          </Form.Item>
          <Form.Item
            key={"1"}
            name={"message"}
            label={"Qo'shimcha"}
            // rules={[{ required: true, message: "Iltimos malumot kiriting!" }]}
          >
            <Input.TextArea
              rows={3}
              name={"Message"}
              placeholder="Izoh qoldirishingiz mumkin"
            />
          </Form.Item>
          <Form.Item valuePropName="checked">
          Ro'yxatdan o'tish bilan <a href="https://docs.google.com/document/d/1VZZqIF2vX_1D008AEvgHViZXZObIh7nNaPv0cSTTU0g/edit">
                  <span style={{color : "blue"}}> Ommaviy oferta va foydalanish shartlariga</span>
                  </a>{" "}
                  rozilik bildirasiz
              </Form.Item>
          <div className="flex items-center justify-end">
            <Button
              variant="secondary"
              onClick={cencelModal}
              size="sm"
              children="Yopish"
              style={{ margin: "0 10px" }}
            />
            <Button htmlType="submit" children="Jo'natish" size="sm" />
          </div>
        </Form>
      </AntdModal>
    </footer>
  );
};

export default Footer;
