import React from "react";
import { Title } from "../../components/Elements";
import ShowCase from "../../components/ShowCase";
import CourseCard from "../../components/CourseCard";
import { Row, Col } from "react-bootstrap";
import { BsJournalCheck } from "react-icons/bs";
import { BiHeadphone } from "react-icons/bi";
import { MdOutlineOndemandVideo } from "react-icons/md"
import s from "./style.module.scss";
export const cards = [
  {
    tag1: "IELTS",
    tag2: "Native Sp",
    title: "Kanadalik mr. Adrianning platformasi.",
    avaImg: "/courseCard/mrAdrian.jpg",
    courseImg: "/courseCard/mrAdrianCourse.jpg",
    userName: "Adrian Benedek",
    link: "/courses/ielts",
  },
  {
    tag1: "CEFR",
    tag2: "Native Sp",
    title: "Angliyalik hamkorlarimiz tomonidan yaratilgan General English kursi",
    avaImg: "/courseCard/perf.jpeg",
    courseImg: "/courseCard/perfCourse.jpeg",
    userName: "David de Jager",
    link: "/courses/perf-spoken",
  },
  {
    tag1: "IELTS",
    tag2: "Writing",
    title: "IELTS darajasi 8.0 tutor bilan ESSAY checking.",
    avaImg: "/courseCard/essay.jpg",
    courseImg: "/courseCard/essayCourse.jpg",
    userName: "Sabrina Asliddinovna",
    link: "/courses/ielts",
  },
  {
    tag1: "IELTS/ENG",
    tag2: "Speaking",
    title: "Angliyalik ustoz Samantha Du Ploy bilan SPEAKING.",
    avaImg: "/courseCard/samantha.jpg",
    courseImg: "/courseCard/samanthaCourse.jpg",
    userName: "Samantha du Ploy",
    link: "/courses/perf-spoken",
  },
  {
    tag1: "ENGLISH",
    tag2: "Individual",
    title: "Zoom orqali o'tadigan aynan sizga mos ustoz.",
    avaImg: "/courseCard/ind.jpg",
    courseImg: "/courseCard/indCourse.jpg",
    userName: "Tez Kunda",
    // link: "/courses/rus-tili",
  },
  {
    tag1: "RUS TILI",
    tag2: "Individual",
    title: "RUS TILI bo'yicha siz istagan zoom o'quvchi.",
    avaImg: "/courseCard/rus.jpg",
    courseImg: "/courseCard/rusCourse.jpg",
    userName: "Tez Kunda",
    // link: "/courses/rus-tili",
  },
];
const index = () => {
  return (
    <div className={s.courses}>
      <ShowCase pagination="COURSES" height="70vh">
        <Title line2="Bizning Kurslarimiz" textAlighCenter={true} />
      </ShowCase>
      <div className="g-container">
        <Row>
          <Col md={4}>
            <div className="blogs__box">
              <div className="blogs__box__title">Categories</div>
              <ul className="blogs__box__list">
                <li>
                  <MdOutlineOndemandVideo size={35} color="#ff5219" />
                  <span>Mr. Adrian bilan zoom uchrashuv</span>
                </li>
                <li>
                  <BsJournalCheck size={35} color="#ff5219" />
                  <span>Essay checking ($3.5)</span>
                </li>
                <li>
                  <MdOutlineOndemandVideo size={35} color="#ff5219" />
                  <span>Samantha bilan jonli zoom uchrashuv</span>
                </li>
                <li>
                  <BiHeadphone size={35} color="#ff5219" />
                  <span>Speaking course with native speaker</span>
                </li>
              </ul>
            </div>
          </Col>
          <Col md={8}>
            <div className={s.courses__wrapper}>
              {cards.map((e, idx) => (
                <div index={idx} className={s.courses__card}>
                  <CourseCard
                    tag1={e.tag1}
                    tag2={e.tag2}
                    title={e.title}
                    userName={e.userName}
                    avaImg={e.avaImg}
                    courseImg={e.courseImg}
                    link={e.link}
                  />
                </div>
              ))}
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default index;
