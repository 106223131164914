import React from "react";
import s from "./style.module.scss";
import { Link } from "react-router-dom";
import { GiStabbedNote } from "react-icons/gi";
import { RiUserStarFill } from "react-icons/ri";
import { FaGraduationCap } from "react-icons/fa";

// children => Title component beriladi
// img => img ga src beriladi
// height => showcase height (value: 50vh or 100vh)
// pagination => bolsa bagination beramiz

const ShowCase = ({ children, img, btn1, btn2, height, pagination }) => {
  return (
    <div
      style={{ height: `calc(${height} - 45px)` }}
      className={s.home__showcase}
    >
      <div className="g-container">
        <div style={{ height: `calc(${height} - 50px)` }}className={s.home__showcaseBox} >
          {children ? (
            <div className={s.home__text} style={{width: img ? "60%" : "100%"}}>
              {children}
              {btn1 || btn2 ? (
                <div className={s.home__btn}>
                  {btn1} {btn2}
                </div>
              ) : null}
            </div>
          ) : null}

          {img ? (
            <div className={s.home__img}>
              <div className={s.home__elemDiv}>
                <div className={s.home__elem}>
                  <div className={s.home__elemIcon}>
                    <GiStabbedNote color="#fff" size={15} />
                  </div>
                  <div className={s.home__elemTitle}>Meni tabriklang!</div>
                  <div className={s.home__elemText}>
                    Onlayn o'qib IELTS 8 oldim
                  </div>
                </div>
                <div className={s.home__elem}>
                  <div className={s.home__elemIcon}>
                    <RiUserStarFill color="#fff" size={15} />
                  </div>
                  <div className={s.home__elemTitle}>Native Speaker darsi</div>
                  <div className={s.home__elemText}>
                    O'rganish 2x tez bo'ladi
                  </div>
                </div>{" "}
                <div className={s.home__elem}>
                  <div className={s.home__elemIcon}>
                    <FaGraduationCap color="#fff" size={15} />
                  </div>
                  <div className={s.home__elemTitle}>300+</div>
                  <div className={s.home__elemText}>Mamnun mijozlar</div>
                </div>
              </div>
              <img src={img} alt="" />
            </div>
          ) : null}
          {pagination ? (
            <div className={s.home__pagination}>
              <Link to="/">HOME</Link> ⮞ <span>{pagination}</span>
            </div>
          ) : null}
        </div>
      </div>
    </div>
  );
};

export default ShowCase;
