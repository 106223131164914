import React from 'react'

const index = () => {
  return (
<form id="form-payme" method="POST" action="https://checkout.paycom.uz">
{/* https://checkout.paycom.uz */}
{/* https://test.paycom.uz */}

 <input type="hidden" name="merchant" value="638494974050a4f685348c1f" />
    <input type="hidden" name="account[order_id]" value="197" />
    <input type="hidden" name="amount" value="500" />
    <input type="hidden" name="lang" value="ru" />
    <input type="hidden" name="button" data-type="svg" value="colored" />
    <div id="button-container"></div>
</form>
  )
}

export default index