import { Col, Container, Row } from "react-bootstrap";
import { Button, Title } from "../../components/Elements";
import ShowCase from "../../components/ShowCase";
import "./styles.scss";

import AvatarImg from "../../assets/sm.blog.png";
import EventImg from "../../assets/1090x500.png";
import Prices from "../../components/Prices";

export default function EILTS() {
  return (
    <div className="course-diteil">
      <ShowCase pagination="COURSE" height="70vh">
        <Title line2="Academic IELTS SUCCESS kursi" textAlighCenter={true} />
      </ShowCase>
      <div className="g-container">
        <Row>
          <Col md={8}>
            <Title line3="Kanadalik mr. Adrian tomonidan tuzilgan Academic IELTS Success kursi." />
            <div className="course-diteil__info flex items-center">
              <div className="info-item flex items-center">
                <img
                  src="/courseCard/mrAdrian.jpg"
                  alt="avatar"
                  className="course-diteil__info__avatar"
                  width="60"
                  height="60"
                />
                <div>
                  <div className="full-name data">Adrian Benedek</div>
                  <span className="status">O'qituvchi</span>
                </div>
              </div>
              <div className="info-item">
                <div className="date data">
                  <Button size="sm" children="IELTS" />
                </div>
                {/* <span className="status">Event Date</span> */}
              </div>
              <div className="info-item">
                <div className="location data">
                  <Button size="sm" variant="info" children="Native Sp" />
                </div>
                {/* <span className="status">Event Location</span> */}
              </div>
              {/* <div className="info-item">
                <div className="time data">10:30am To 2:30pm</div>
                <span className="status">Event Time</span>
              </div> */}
            </div>
            <img
              src="/courseCard/mrAdrianCourse.jpg"
              alt="event"
              className="course-diteil__image"
              width="600"
            />
            <div className="course-diteil__title">Kurs haqida</div>
            <div className="vent-diteil__desc">
              Bizning kompaniyamiz MyTeacher O'zbekistonda birinchi bo'lib mr.
              Adrian bilan u kishining 100 soatga mo'ljallangan “Academic IELTS
              sucsess” deb nomlangan kursini O'zbekiston IELTS o'rganuvchilariga
              ENG arzon narxda taqdim qilish ya'ni uni sotish bo'yicha
              Eksklyuziv shartnoma imzoladi. <br/> <br/> Bu kurs Mr Adrianning 20 yillik
              tajribasidan kelib chiqib, shaxsan o'zi tomonidan tuzilgan va aniq
              strategiyalar bilan tushuntirib berilgan. <br/> Bu kursning asosiy qismi
              "Self study" bo'lib ichida: <br/> -mr. Adrian tomonidan yozilgan, IELTSga
              boshqichma bosqich tayyorlanishga mo’ljallangan maxsus
              videokurslar.<br/> -Manashu har bir videokurs uchun interaktiv mashqlar
              <br/>-Har bir mashq oxirida testlar. Ya'ni siz birinchisini bajarib
              bo’lsangiz ikkinchisiga o’tasiz.<br/> -Yana bir ustunlik jihati
              shundaki Butun kurs davomida kompyuterlashtirilghan 6 ta mock
              testni BEPUL topshirsangiz bo’ladi.<br/> -Kurs o’z ichiga hamma
              ko’nikmalar - Speaking, Reading, Writing va Listening bo’yicha eng
              foydali materiallar va mavzularni oladi.<br/><br/> Bonuslar: Siz bu kursga
              yozilsangiz sizni alohida yopiq telegram guruhga qo'shamiz va 4 oy
              davomida haftada bir necha marta zoom orqali bo'ladigan
              darslarimizga bepul qatnashasiz u yerda IELTS darajasi kamida 8
              bo’lgan ustozlar Manashu kurs mavzulari boyicha har qanday
              savolizga javob berib o’rganishizni nazorat qilib borishadi.<br/><br/> Bir
              oyda bir marta shaxsan mr Adrianning o’zi bizning o’quvchilar
              bilan onlayn uchrashuv o’tkazadi. Ya’ni siz shaxsan Adrian
              janoblarining o'zi bilan ham Ingliz tilida gaplashish imkoniyatiga
              ega bo’lishingiz mumkin.
            </div>
          </Col>
          <Col md={4}>
            <div className="course-diteil__right">
              <div className="course-diteil__rightItem">
                <p>Kurs o'z ichiga oladi:</p>
                <ul className="course-diteil__list">
                  <li>
                    <img src="/homePage/checked2.png" alt="img" />
                    <span>Shaxsan mr. Adrian bilan zoom uchrashuv</span>
                  </li>
                  <li>
                    <img src="/homePage/checked2.png" alt="img" />
                    <span>Student partner speaking</span>
                  </li>
                  <li>
                    <img src="/homePage/checked2.png" alt="img" />
                    <span>Bepul mock testlar</span>
                  </li>
                  <li>
                    <img src="/homePage/checked2.png" alt="img" />
                    <span>Yopiq telegram guruh</span>
                  </li>
                </ul>
              </div>
              <div className="course-diteil__rightItem">
                <p>Qo'shimcha Bonuslar</p>
                <ul className="course-diteil__list">
                  <li>
                    <img src="/homePage/checked2.png" alt="img" />
                    <span>Darslik va materiallar</span>
                  </li>
                  <li>
                    <img src="/homePage/checked2.png" alt="img" />
                    <span>Essay checking (3.5$)</span>
                  </li>
                  <li>
                    <img src="/homePage/checked2.png" alt="img" />
                    <span>O’rganish rejasi (study plan)</span>
                  </li>
                  <li>
                    <img src="/homePage/checked2.png" alt="img" />
                    <span>Kurator qo’llab quvvatlash</span>
                  </li>
                </ul>
              </div>
            </div>
          </Col>
        </Row>
      </div>
      {/* <Container style={{ marginTop: "50px" }}>
        <Prices course="IELTS" />
      </Container> */}
    </div>
  );
}
