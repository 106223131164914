import './style.scss'
import BlogImg from '../../assets/blogImg.png'

import {FaRegComment} from 'react-icons/fa'
import {GrSchedule} from 'react-icons/gr'
import { NavLink } from 'react-router-dom'

export default function BlogCard() {
  return (
    <NavLink to='/' className='blog-card' >
        <img src={BlogImg} alt="blog" width='300' height='180' className="blog-card__img" />
        <div className="blog-card__body">
            <div className="title">Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos.</div>
            <div className="desc">Donec accumsan enim sit amet dolor rhoncus scelerisque. Suspendisse dictum, enim a interdum facilisis, ex diam dignissim ligula, sit amet commodo est nunc vulputate turpis.</div>
            <div className="data">
                <div className="data__item schedule"><GrSchedule className='icon' /> John Doe</div>
                <div className="data__item comments"><FaRegComment className='icon' /> 07 Jan, 2022</div>
            </div>
        </div>
    </NavLink>
  )
}
