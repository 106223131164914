import { NavLink } from "react-router-dom";
import "./styles.scss";

/**
 *
 * @param {
 *  children: string, // * Button text content
 *  size: sm | md | lg, // * Button size
 *  as: string,          // Button html element
 *  className: string,  // Button className
 *  icon: React.Element, // Button icon
 *  variant: 'primary' | 'secondary' | 'info',
 *  noEffect: boolean, // Button hover effect
 *  url: string, // Button url,
 *  fullWidth: boolean, // Button width, 
 * } = props
 */

export default function Button({
  children = "",
  size = "md",
  variant = "primary",
  as: Element = "button",
  className = "",
  icon = null,
  noEffecct = false,
  fullWidth = false,
  to,
  ...rest
}) {
  const sizes = {
    sm: "button__size__sm",
    md: "button__size__md",
    lg: "button__size__lg",
  };
  const variants = {
    primary: "button__primary",
    secondary: "button__secondary",
    info: "button__info",
  };

  const _className = `
    button ${className} 
    ${sizes[size]} 
    ${variants[variant]} 
    ${noEffecct ? "" : "button__effect"} 
    ${fullWidth ? 'w-full':'w-fit'}
  `;

  return (
    <Element className={_className} {...rest}>
      {icon && <span className="button__icon">{icon}</span>} {children}
      {to && <NavLink to={to} className="button__link" />}
    </Element>
  );
}
