import s from "./style.module.scss";
import "./antModalStyle.scss";

import { Title } from "../../components/Elements";
import { Button } from "../../components/Elements";
import HomeShowCase from "../../components/ShowCase/HomeShowCase";
// import PoppularCourses from "../../components/PopularCourses";
import { BsPlayCircleFill } from "react-icons/bs";
import { FaGraduationCap, FaUserTie } from "react-icons/fa";
import Feedback from "../../components/Feedback";
import OurTeam from "../../components/OurTeam";
// import Prices from "../../components/Prices";
import Chat from "../../components/Chat";
// import Modal from "../../components/Modal/index";
import { cards } from "../Courses";
import CourseCard from "../../components/CourseCard";
import {
  Modal as AntdModal,
  Modal as IsPopUp,
  Input,
  message,
  Checkbox,
} from "antd";
import Form from "antd/es/form/Form";
import axios from "axios";
import { useState } from "react";
import { Link } from "react-router-dom";

export default function Homepage() {
  // Modal
  const [formData] = Form.useForm();
  // const [loading, setLoading] = useState(false);
  const [number, setNumber] = useState(false);
  // const handleOk = () => {};
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpenGreat, setIsModalOpenGreat] = useState(
    localStorage.getItem("greet") ? false : false
  );
  const [popUp, setpopUp] = useState(false);

  const handleChange = (e) => {
    const { value } = e.target;
    const formattedValue = formatPhoneNumber(value);
    formData.setFieldValue("phone", formattedValue);
  };

  const formatPhoneNumber = (value) => {
    // Удаляем все нецифровые символы из введенного значения
    const cleanedValue = value.replace(/\D/g, "");

    // Форматируем номер в требуемый формат
    let formattedValue = cleanedValue;
    if (cleanedValue.length > 2) {
      formattedValue = cleanedValue.replace(
        /(\d{2})(\d{3})(\d{2})(\d{2})/,
        "($1) $2-$3-$4"
      );
    } else if (cleanedValue.length > 0) {
      formattedValue = cleanedValue.replace(/(\d{1,2})/, "($1");
    }

    return formattedValue;
  };
  const cencelModal = () => {
    formData.resetFields();
    setIsModalOpen(false);
    setIsModalOpenGreat(false);
  };
  const openModal = () => {
    setIsModalOpen(true);
  };

  const handleSubmit = async (data) => {
    try {
      // send to server
      const resp = axios.post("https://myteacher.uz/api/register", {
        name: data.fullName,
        phone: `998${data.phone
          ?.replaceAll(" ", "")
          .replaceAll("-", "")
          .replace("(", "")
          .replace(")", "")}`,
        comment: data.message,
      });
      resp
        .then((e) => {
          console.log(e);
        })
        .then(() => {
          if (number) {
            setNumber(false);
            window.location.href = "https://t.me/+qcUATd4xvqU3ZGRi";
          }
          localStorage.setItem("greet", "true");
          setIsModalOpen(false);
          setpopUp(true);
          setIsModalOpenGreat(false);
        });
    } catch (e) {
      // show notify
      message.error("Something went wrong!");
      if (number) {
        setNumber(false);
        window.location.href = "https://t.me/+qcUATd4xvqU3ZGRi";
      }
    } finally {
      setpopUp(true);
      setIsModalOpenGreat(false);
    }
  };

  const [disable, setDisable] = useState(false);
  const ommaviyOff = (e) => {
    setDisable(e.target.checked);
  };
  return (
    <div className={s.home_page}>
      {/* <Chat /> */}
      {/* <Modal open={false} /> */}
      <HomeShowCase
        height={"100vh"}
        img="/homePage/women.png"
        btn1={
          <Button>
            <a href="#courses">TANISHIB CHIQING</a>
          </Button>
        }
        btn2={
          <Button variant="secondary">
            <Link to="/test"> DARAJANGIZNI BILIB OLING</Link>
          </Button>
        }
      >
        <Title
          line1={"ISTAGAN VAQT, ISTAGAN YERDA, ONLAYN"}
          line2={"IELTS va Ingliz tili"}
          underLine2="2x tezroq"
          imgResp="/homePage/women.png"
          line4="Ona tilisi ingliz bo'lgan ustozlarimizdan kuchli platforma hamda zoom darslarini oling. Yoki o'zingizga individual ustoz tanlang!"
        />
      </HomeShowCase>
      {/* <div className={s.pop}>
        <PoppularCourses />
      </div> */}
      <div className={s.courses} id="courses">
        <div className="g-container">
          <div className={s.courses__title}>
            <div className={s.courses__titleTxt}>
              <Title
                line1="BIZNING ONLAYN KURSLARIMIZ"
                line3="O'zingizga mos Onlayn kursni tanlang!"
              />
            </div>
            <Button onClick={openModal}>MASLAHAT OLISH</Button>
          </div>
          <div className={s.courses__cards}>
            {cards.map((e, idx) => (
              <div index={idx} className={s.courses__card}>
                <CourseCard
                  tag1={e.tag1}
                  tag2={e.tag2}
                  title={e.title}
                  userName={e.userName}
                  avaImg={e.avaImg}
                  courseImg={e.courseImg}
                  link={e.link}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className={s.about}>
        <div className={`${s.about__box} g-container`}>
          <div className={s.about__img}>
            <div className={s.about__elemDiv}>
              <div className={s.about__elem}>
                <div className={s.about__elemIcon}>
                  <FaUserTie color="#fff" size={15} />
                </div>
                <div className={s.about__elemTitle}>Sardor Xudoyberdiyev</div>
                <div className={s.about__elemText}>TESOL sertifikatiga ega</div>
              </div>
              <div className={s.about__elem}>
                <div className={s.about__elemIcon}>
                  <FaGraduationCap color="#fff" size={15} />
                </div>
                <div className={s.about__elemTitle}>2000+</div>
                <div className={s.about__elemText}>O'quvchilar</div>
              </div>
            </div>
            <img src="/homePage/aboutIMG.png" alt="img" />
          </div>
          <div className={s.about__text}>
            <Title
              line1="ASSALOMU ALAYKUM "
              line3="Nega Siz aynan bizni tanlashingiz kerak?"
              line4="Mening ismim Sardor, shu loyihaning asoschisi bo‘laman. 10 yil o‘qishdan yiqildim, oxiri ustoz bilan atigi 5 oy tayyorlanib yuqori ball bilan o‘qishga kirdim va shunga qaror qildimki, ilm izlovchilari men kabi vaqt va umrini yo'qotmay tezroq natijaga erishsinlar."
            />
            <li className={s.about__respImg}>
              <div className={s.about__elemDiv}>
                <div className={s.about__elem}>
                  <div className={s.about__elemIcon}>
                    <FaUserTie color="#fff" size={20} />
                  </div>
                  <div className={s.about__elemTitle}>Sardor Xudoyberdiyev</div>
                  <div className={s.about__elemText}>
                    TESOL sertifikatiga ega
                  </div>
                </div>
                <div className={s.about__elem}>
                  <div className={s.about__elemIcon}>
                    <FaGraduationCap color="#fff" size={20} />
                  </div>
                  <div className={s.about__elemTitle}>2000+</div>
                  <div className={s.about__elemText}>O'quvchilar</div>
                </div>
              </div>
              <img src="/homePage/aboutIMG.png" alt="pic" />
            </li>
            <ul className={s.about__list}>
              <li>
                <img src="/about/icons/checked.png" alt="alt" /> O'zbekistonda
                turib Angliya va Kanadalik ustozlardan ONLAYN zoom darslar.
              </li>
              <li>
                <img src="/about/icons/checked.png" alt="alt" /> Nol darajadan
                boshlab Ingliz ustozlardan o'rganish bu 2 barobar tez.
              </li>
              <li>
                <img src="/about/icons/checked.png" alt="alt" /> Onlayn ta'lim
                sohasida 5 yildan ortiq tajribamiz bilan sizga astoydil xizmat
                ko'rsatamiz.
              </li>
            </ul>
            <Button onClick={openModal} children="KURSGA YOZILISH" />
          </div>
        </div>
      </div>
      <div className={s.live}>
        <div className={`${s.live__box} g-container`}>
          <div className={s.live__img}>
            <img src="/homePage/live.png" alt="img" />
            <a href="https://t.me/Myteacher_IELTS">
              <button>
                <BsPlayCircleFill size={40} />
                YOZILING
              </button>
            </a>
          </div>
          <div className={s.live__text}>
            <Title
              line1="NAVBATDAGI JONLI WEBINAR"
              line3="MyTeacher talabalari uchun navbatdagi jonli online darslar"
            />
            <a href="https://t.me/myteacherspeaking">
              <button
              // onClick={() => {
              //   openModal();
              //   setNumber(true);
              // }}
              >
                <div>
                  <p>
                    <span>13-14</span>Iyun
                  </p>
                  <h6>10:00-11:30</h6>
                  <h5>ONLINE</h5>
                  <h4>Navbatdagi Speaking dars</h4>
                </div>
              </button>
            </a>
            <a href="https://t.me/myteacherspeaking">
              <button
              // onClick={() => {
              //   openModal();
              //   setNumber(true);
              // }}
              >
                <div>
                  <p>
                    <span>13-14</span> Iyun
                  </p>
                  <h6>15:00-16:30</h6>
                  <h5>ONLINE</h5>
                  <h4>Navbatdagi Speaking dars</h4>
                </div>
              </button>
            </a>
            <a href="https://t.me/myteacherspeaking">
              <button
              // onClick={() => {
              //   openModal();
              //   setNumber(true);
              // }}
              >
                <div>
                  <p>
                    <span>13-14</span> Iyun
                  </p>
                  <h6>20:00-21:30</h6>
                  <h5>ONLINE</h5>
                  <h4>Navbatdagi Speaking dars</h4>
                </div>
              </button>
            </a>
          </div>
        </div>
      </div>
      <div className={s.mission}>
        <div className={s.mission__div2}>
          <div className={`${s.missoin__box} g-container`}>
            <div className={s.mission__text}>
              <Title
                line1="BIZNING MISSIYAMIZ"
                line3="Xizmat ko‘rsatib nima topamiz?"
              />
              <div className={s.mission__card}>
                <img src="/homePage/mssionIcon1.png" alt="" />
                <div>
                  <h5>O‘quvchining oxirgi manzili</h5>
                  <h6>
                    Biz o'quvchilarga ingliz tili, rus tili va IELTSni shunday
                    o'rgataylikki, u hayotida hech qachon bu bo‘yicha boshqa
                    kurs qidirmasin!
                  </h6>
                </div>
              </div>
              <div className={s.mission__card}>
                <img src="/homePage/mssionIcon2.png" alt="" />
                <div>
                  <h5>O‘quvchining "tili chiqsin"</h5>
                  <h6>
                    Native Speaker (ona tilisi ingliz bo'lgan ustoz) bilan
                    shunday o'rgansinki, tili, yosh bolani tili chiqqandek,
                    tabiiy va tez chiqsin.
                  </h6>
                </div>
              </div>

              <div className={s.mission__card}>
                <img src="/homePage/mssionIcon3.png" alt="" />
                <div>
                  <h5>Ish, o'qish va turmush muammo emas</h5>
                  <h6>
                    Bizning o'quvchilar istagan joyda, istagan vaqtda, istagan
                    miqdor va sifatda ilm olishsin. Maksimum qulaylikka ega
                    bo'lishsin.
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={s.mission__div}>
          <img src="/homePage/missionMain.jpg" alt="img" />
        </div>
      </div>
      <div className={s.team}>
        <div className={`${s.team__box} g-container`}>
          <OurTeam />
        </div>
      </div>
      <div className={s.feedback}>
        <div className="g-container">
          <Feedback />
        </div>
      </div>
      <div className={s.blog}>
        <div className={`${s.blog__box} container`}>
          <Title
            line1="MYTEACHER BILAN YANA HAM BATAFSILROQ TANISHING"
            line3="Darslardan Namunalar"
            textAlighCenter={true}
          />
          <div className={s.blog__cards}>
            <div className={s.blog__card}>
              <div className={s.blog__img}>
                <iframe
                  //   width="560"
                  //   height="315"
                  src="https://www.youtube.com/embed/pxbnbUeFhII"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
              </div>
              <div className={s.blog__text}>
                <p>Mr. Adrian bilan zoomda Speaking dars!</p>
              </div>
            </div>
            <div className={s.blog__card}>
              <div className={s.blog__img}>
                <iframe
                  //   width="560"
                  //   height="315"
                  src="https://www.youtube.com/embed/MM0MIUxwMUI"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
              </div>
              <div className={s.blog__text}>
                <p>MyTeacher | Perfectly Spoken platformasidan foydalanish</p>
              </div>
            </div>
            <div className={s.blog__card}>
              <div className={s.blog__img}>
                <iframe
                  //   width="560"
                  //   height="315"
                  src="https://www.youtube.com/embed/W-cxDc6r0XY"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
              </div>
              <div className={s.blog__text}>
                <p>MyTeacher | Mr. Adrianning Platformasi bilan tanishuv</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <AntdModal
        open={isModalOpen}
        footer={null}
        // closable={false}
        onCancel={() => {
          setIsModalOpen(false);
        }}
      >
        <p>
          <strong style={{ fontSize: "20px" }}>
            Alaykum assalom, MyTeacherga xush kelibsiz! Iltimos ismingiz va
            telefon raqamingizni qoldiring. Siz bilan tez orada bog'lanamiz.
          </strong>
        </p>
        <Form
          name="normal_login"
          className="login-form"
          onFinish={handleSubmit}
          form={formData}
        >
          <Form.Item
            key={"1"}
            name={"fullName"}
            label={"Ismingiz"}
            rules={[
              { required: true, message: "Iltimos ismingizni kiriting!" },
            ]}
          >
            <Input
              type={"text"}
              name={"fullName"}
              placeholder="Sizning ismingiz"
            />
          </Form.Item>
          <Form.Item
            key={"1"}
            name={"phone"}
            label={"Raqamingiz"}
            rules={[
              { required: true, message: "Iltimos raqmingizni kiriting!" },
            ]}
          >
            <Input
              prefix="+998"
              onChange={handleChange}
              maxLength={14}
              type={"tel"}
              name={"number"}
              placeholder="(__) ___ - __ - __"
            />
          </Form.Item>
          <Form.Item
            key={"1"}
            name={"message"}
            label={"Qo'shimcha"}
            // rules={[{ required: true, message: "Iltimos malumot kiriting!" }]}
          >
            <Input.TextArea
              rows={3}
              name={"Message"}
              placeholder="Izoh qoldirishingiz mumkin"
            />
          </Form.Item>
          <Form.Item valuePropName="checked">
            Ro'yxatdan o'tish bilan{" "}
            <a href="https://docs.google.com/document/d/1VZZqIF2vX_1D008AEvgHViZXZObIh7nNaPv0cSTTU0g/edit">
              <span style={{ color: "blue" }}>
                {" "}
                Ommaviy oferta va foydalanish shartlariga
              </span>
            </a>{" "}
            rozilik bildirasiz.
          </Form.Item>
          <div className="flex items-center justify-end">
            <Button
              variant="secondary"
              onClick={cencelModal}
              size="sm"
              children="Yopish"
              style={{ margin: "0 10px" }}
            />
            <Button htmlType="submit" children="Jo'natish" size="sm" />
          </div>
        </Form>
      </AntdModal>

      {/* GreetingModal */}

      <AntdModal open={isModalOpenGreat} footer={null} closable={false}>
        <Form
          name="normal_login"
          className="login-form"
          onFinish={handleSubmit}
          form={formData}
        >
          <Form.Item
            key={"1"}
            name={"fullName"}
            label={"Ismingiz"}
            rules={[
              { required: true, message: "Iltimos ismingizni kiriting!" },
            ]}
          >
            <Input
              type={"text"}
              name={"fullName"}
              placeholder="Sizning ismingiz"
            />
          </Form.Item>
          <Form.Item
            key={"1"}
            name={"phone"}
            label={"Raqamingiz"}
            rules={[
              { required: true, message: "Iltimos raqmingizni kiriting!" },
            ]}
          >
            <Input
              prefix="+998"
              onChange={handleChange}
              maxLength={14}
              type={"tel"}
              name={"number"}
              placeholder="(__) ___ - __ - __"
            />
          </Form.Item>
          <Form.Item
            key={"1"}
            name={"message"}
            label={"Qo'shimcha"}
            // rules={[{ required: true, message: "Iltimos malumot kiriting!" }]}
          >
            <Input.TextArea
              rows={3}
              name={"Message"}
              placeholder="Izoh qoldirishingiz mumkin"
              rules={[
                { required: true, message: "Iltimos raqmingizni kiriting!" },
              ]}
            />
          </Form.Item>
          Ro'yxatdan o'tish bilan{" "}
          <a href="https://docs.google.com/document/d/1VZZqIF2vX_1D008AEvgHViZXZObIh7nNaPv0cSTTU0g/edit">
            <span style={{ color: "blue" }}>
              {" "}
              Ommaviy oferta va foydalanish shartlariga
            </span>
          </a>{" "}
          rozilik bildirasiz.
          <div className="flex items-center justify-end">
            <Button htmlType="submit" children="Ok" size="sm" />
          </div>
        </Form>
      </AntdModal>

      {/* Popup modal */}
      <IsPopUp
        width={700}
        open={false}
        closable={false}
        footer={null}
        className="greet"
      >
        <img style={{ width: "100%" }} src="/homePage/popUp.jpg" alt="img" />
        <button
          className="greet__btn"
          onClick={() => {
            setpopUp(false);
          }}
        >
          HA!
        </button>
      </IsPopUp>
    </div>
  );
}
