import React, { useRef, useState } from "react";
import s from "./style.module.scss";
import { Button, Checkbox, Form, Input, message, Modal } from "antd";
import TextArea from "antd/es/input/TextArea";
import axios from "axios";
import { Link } from "react-router-dom";
axios.defaults.headers = {
  "X-Auth": "638494974050a4f685348c1f",
  "Content-Type": "application/json",
};

const PaymePage = () => {
  const [sms, setSms] = useState(false);
  const [disable, setDisable] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const code = useRef();
  const selectedPlan = localStorage.getItem("selectedPlan");
  const selectedCourse = localStorage.getItem("selectedCourse");
  const IELTS = {
    Econom: {
      price: 39900000,
      duration: "1 yil",
    },
    Standart: {
      price: 89900000,
      duration: "4 oy",
    },
    Premium: {
      price: 149900000,
      duration: "1 yil",
    },
    VIP: {
      price: 229900000,
      duration: "1 yil",
    },
    Comfort: {
      price: 69900000,
      duration: "1 oylik",
    },
  };
  const PerfSpoken = {
    Econom: {
      price: 79900000,
      duration: "6 oy",
    },
    Standart: {
      price: 129900000,
      duration: "6 oy",
    },
    Premium: {
      price: 199900000,
      duration: "6 oy",
    },
    VIP: {
      price: 279900000,
      duration: "6 oy",
    },
    Comfort: {
      price: 49900000,
      duration: "1 oylik",
    },
  };
  const price =
    selectedCourse === "IELTS"
      ? selectedPlan === "Econom"
        ? IELTS.Econom.price
        : selectedPlan === "Standart"
        ? IELTS.Standart.price
        : selectedPlan === "Premium"
        ? IELTS.Premium.price
        : selectedPlan === "VIP"
        ? IELTS.VIP.price
        : selectedPlan === "Comfort"
        ? IELTS.Comfort.price
        : null
      : selectedCourse === "PerfSpoken"
      ? selectedPlan === "Econom"
        ? PerfSpoken.Econom.price
        : selectedPlan === "Standart"
        ? PerfSpoken.Standart.price
        : selectedPlan === "Premium"
        ? PerfSpoken.Premium.price
        : selectedPlan === "VIP"
        ? PerfSpoken.VIP.price
        : selectedPlan === "Comfort"
        ? PerfSpoken.Comfort.price
        : null
      : null;
  const createCard = async (e) => {
    try {
      const resp = axios.post("https://checkout.paycom.uz/api", {
        method: "cards.create",
        params: {
          card: {
            number: e.number,
            expire: e.expire,
          },
          customer: e.customer,
          amount: price,
          save: false,
        },
      });
      resp.then((e) => {
        try {
          message.success("create-card");
          cardsGetVerifyCode(e);
          localStorage.setItem("token", e.data.result.card.token);
        } catch {
          message.error(e.data.error.message);
        }
      });
    } catch (error) {
      message.error(error.message);
    }
  };
  const cardsGetVerifyCode = async (e) => {
    try {
      const resp = axios.post("https://checkout.paycom.uz/api", {
        method: "cards.get_verify_code",
        params: {
          token: e.data.result.card.token,
        },
      });
      resp.then((e) => {
        try {
          setSms(e.data.result.sent);
          window.scrollTo(0, 100);
        } catch {
          message.error(e.data.error.message);
        }
      });
    } catch (error) {
      message.error(error.message);
    }
  };
  const cardsVerify = async (e) => {
    try {
      const resp = axios.post("https://checkout.paycom.uz/api", {
        method: "cards.verify",
        params: {
          token: localStorage.getItem("token"),
          code: e,
        },
      });
      resp.then((e) => {
        try {
          cardsCheck(e.data.result.card.token);
          message.success("cards-verify");
        } catch {
          message.error(e.data.error.message);
        }
      });
    } catch (error) {
      message.error(error.message);
    }
  };
  const cardsCheck = async (e) => {
    try {
      const resp = axios.post("https://checkout.paycom.uz/api", {
        method: "cards.check",
        params: {
          token: e,
        },
      });
      resp.then((e) => {
        try {
          if (e.data.result.card.verify) {
            setIsModalOpen(true);
            payment(e.data.result.card.token);
          }
          e.data.result.card.verify
            ? setIsModalOpen(true)
            : message.error("Something went wrong!");
        } catch {
          message.error(e.data.error.message);
        }
      });
    } catch (error) {
      message.error(error.message);
    }
  };
  const userInfo = async (e) => {
    try {
      const resp = axios.post("https://myteacher.uz/api/createpayment", {
        title: `${selectedCourse} - ${selectedPlan}`,
        name: e.name,
        phone: e.customer,
      });
      resp.then((e) => {
        message.success("succes");
      });
    } catch (error) {
      message.error(error.message);
    }
  };
  const payment = async (e) => {
    try {
      const resp = axios.post("https://myteacher.uz/api/checkoutpayment", {
        token: e,
        amount: price,
        phone: `998${document
          .getElementById("phone")
          ?.value.replaceAll(" ", "")
          .replaceAll("-", "")
          .replace("(", "")
          .replace(")", "")}`,
      });
      resp.then((e) => {
        message.success("succes");
        console.log(e);
      });
    } catch (error) {
      message.error(error.message);
    }
  };
  const Verify = () => {
    cardsVerify(code.current.input.value);
  };
  const onFinish = (values) => {
    createCard(values);
    userInfo(values);
  };

  const onFinishFailed = (errorInfo) => {
    message.error(errorInfo);
  };
  const onChange = (e) => {
    setDisable(e.target.checked);
  };
  const handleOk = () => {
    window.location.href = "/";
    setIsModalOpen(false);
  };

  const [form] = Form.useForm();
  const handleCardNumberChange = (e) => {
    const { value } = e.target;
    // Форматирование ввода номера карты
    const formattedValue = value
      .replace(/[^\d]/g, "")
      .replace(/(.{4})/g, "$1 ")
      .trim();

    form.setFieldValue("number", formattedValue);
  };

  const handleChange = (e) => {
    const { value } = e.target;
    const formattedValue = formatPhoneNumber(value);
    form.setFieldValue("customer", formattedValue);
  };

  const formatPhoneNumber = (value) => {
    // Удаляем все нецифровые символы из введенного значения
    const cleanedValue = value.replace(/\D/g, "");

    // Форматируем номер в требуемый формат
    let formattedValue = cleanedValue;
    if (cleanedValue.length > 2) {
      formattedValue = cleanedValue.replace(
        /(\d{2})(\d{3})(\d{2})(\d{2})/,
        "($1) $2-$3-$4"
      );
    } else if (cleanedValue.length > 0) {
      formattedValue = cleanedValue.replace(/(\d{1,2})/, "($1");
    }

    return formattedValue;
  };

  const handleChangeExpire = (e) => {
    const { value } = e.target;
    const formattedValue = formatExpiration(value);
    // setExpiration(formattedValue);
    form.setFieldValue("expire", formattedValue);
  };

  const formatExpiration = (value) => {
    // Удаляем все нецифровые символы из введенного значения
    const cleanedValue = value.replace(/\D/g, "");

    // Добавляем пробелы после первых двух символов
    let formattedValue = cleanedValue;
    if (cleanedValue.length > 2) {
      formattedValue = cleanedValue.replace(/(\d{2})(\d{2})/, "$1 / $2");
    }

    return formattedValue;
  };
  return (
    <div className={`${s.payme}`}>
      <Form
        form={form}
        name="basic"
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 16 }}
        style={{ maxWidth: 600 }}
        initialValues={{ remember: true }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
        layout="vertical"
        className="payme__form"
      >
        <div className={s.payme__body}>
          <div className={s.payme__box}>
            <div className={s.payme__left}>
              <div className={s.payme__logo}>
                <Link to="/">
                  <img src="/about/logo/logo.svg" alt="img" />
                </Link>
              </div>
              <div className={s.payme__title}>
                {selectedCourse === "IELTS"
                  ? "Academic IELTS SUCCESS"
                  : selectedCourse === "PerfSpoken"
                  ? "Perfectly Spoken kursi"
                  : null}{" "}
              </div>
              <Form.Item
                label="Ismingiz"
                name="name"
                rules={[
                  { required: true, message: "Iltimos ismingizni kiriting!" },
                ]}
              >
                <Input className="antd-input" placeholder="Sizning ismingiz" />
              </Form.Item>

              <Form.Item
                label="Telefon raqamingiz"
                name="customer"
                rules={[
                  {
                    required: true,
                    message: "Iltimos telefon raqamingizni kiriting!",
                  },
                ]}
              >
                <Input
                  id="phone"
                  className="antd-input"
                  placeholder="(**) *** ** **"
                  htmlType="tel"
                  prefix="+998"
                  onChange={handleChange}
                  maxLength={14}
                />
              </Form.Item>

              <Form.Item
                label="Karta raqamingiz"
                name="number"
                rules={[
                  {
                    required: true,
                    message: "Iltimos karta raqamingizni kiriting!",
                  },
                ]}
              >
                <Input
                  type="text"
                  onChange={handleCardNumberChange}
                  placeholder="8600 **** **** ****"
                  maxLength={19}
                />
              </Form.Item>
              <Form.Item>
                <Form.Item
                  style={{
                    display: "inline-block",
                    width: "calc(40% - 4px)",
                  }}
                  label="Amal&nbsp;qilish&nbsp;muddati"
                  name="expire"
                  rules={[
                    {
                      required: true,
                      message: "Iltimos karta raqamingizni kiriting!",
                    },
                  ]}
                >
                  <Input
                    className="antd-input"
                    type="text"
                    onChange={handleChangeExpire}
                    placeholder="MM / YY"
                    maxLength={7} // Учитываем пробелы и символ "/"
                  />
                </Form.Item>
                {sms ? (
                  <>
                    <Form.Item
                      hidden={true}
                      label="SMS&nbsp;kod"
                      name="code"
                      style={{
                        display: "inline-block",
                        width: "calc(25% - 4px)",
                        margin: "0 15px",
                      }}
                      rules={[
                        {
                          required: true,
                          message: "SMS kodni kiriting!",
                        },
                      ]}
                    >
                      <Input
                        className="antd-input"
                        ref={code}
                        placeholder="1234"
                        type="number"
                      />
                    </Form.Item>
                    <Form.Item
                      hidden={sms}
                      style={{
                        display: "inline-block",
                        width: "calc(25% - 4px)",
                        margin: "30px 0 0",
                      }}
                    >
                      <Button type="primary" onClick={Verify}>
                        Tasdiqlash
                      </Button>
                    </Form.Item>
                  </>
                ) : null}
              </Form.Item>
            </div>
            <div className={s.payme__right}>
              <div className={s.payme__title} style={{ paddingBottom: "20px" }}>
                To'lov ma'lumotlari
              </div>
              <p>
                {" "}
                {selectedCourse === "IELTS" ? (
                  <>
                    {" "}
                    <span>
                      Academic IELTS kursi <br /> {selectedPlan}
                    </span>{" "}
                    {selectedPlan === "Econom" ? (
                      <span>399.000 UZS</span>
                    ) : selectedPlan === "Standart" ? (
                      <span>899.000 UZS</span>
                    ) : selectedPlan === "Premium" ? (
                      <span>1.499.000 UZS</span>
                    ) : selectedPlan === "VIP" ? (
                      <span>2.299.000 UZS</span>
                    ) : selectedPlan === "Comfort" ? (
                      <span>699.000 UZS</span>
                    ) : null}
                  </>
                ) : selectedCourse === "PerfSpoken" ? (
                  <>
                    {" "}
                    <span>
                      Perfectly Spoken kursi <br /> {selectedPlan}
                    </span>{" "}
                    {selectedPlan === "Econom" ? (
                      <span>799.000 UZS</span>
                    ) : selectedPlan === "Standart" ? (
                      <span>1.299.000 UZS</span>
                    ) : selectedPlan === "Premium" ? (
                      <span>1.999.000 UZS</span>
                    ) : selectedPlan === "VIP" ? (
                      <span>2.799.000 UZS</span>
                    ) : selectedPlan === "Comfort" ? (
                      <span>499.000 UZS</span>
                    ) : null}
                  </>
                ) : null}
              </p>
              <p>
                <span>Muddat</span>
                {selectedCourse === "IELTS" ? (
                  <>
                    {selectedPlan === "Econom" ? (
                      <span>1 yil</span>
                    ) : selectedPlan === "Standart" ? (
                      <span>4 oy</span>
                    ) : selectedPlan === "Premium" ? (
                      <span>1 yil</span>
                    ) : selectedPlan === "VIP" ? (
                      <span>1 yil</span>
                    ) : selectedPlan === "Comfort" ? (
                      <span>1 oylik</span>
                    ) : null}
                  </>
                ) : selectedCourse === "PerfSpoken" ? (
                  <>
                    {selectedPlan === "Econom" ? (
                      <span>6 oy</span>
                    ) : selectedPlan === "Standart" ? (
                      <span>6 oy</span>
                    ) : selectedPlan === "Premium" ? (
                      <span>6 oy</span>
                    ) : selectedPlan === "VIP" ? (
                      <span>6 oy</span>
                    ) : selectedPlan === "Comfort" ? (
                      <span>1 oylik</span>
                    ) : null}
                  </>
                ) : null}
              </p>
              <p>
                <span>Umumiy Xisob</span>
                {selectedCourse === "IELTS" ? (
                  <>
                    {selectedPlan === "Econom" ? (
                      <span>399.000 UZS</span>
                    ) : selectedPlan === "Standart" ? (
                      <span>899.000 UZS</span>
                    ) : selectedPlan === "Premium" ? (
                      <span>1.499.000 UZS</span>
                    ) : selectedPlan === "VIP" ? (
                      <span>2.299.000 UZS</span>
                    ) : selectedPlan === "Comfort" ? (
                      <span>699.000 UZS</span>
                    ) : null}
                  </>
                ) : selectedCourse === "PerfSpoken" ? (
                  <>
                    {selectedPlan === "Econom" ? (
                      <span>799.000 UZS</span>
                    ) : selectedPlan === "Standart" ? (
                      <span>1.299.000 UZS</span>
                    ) : selectedPlan === "Premium" ? (
                      <span>1.999.000 UZS</span>
                    ) : selectedPlan === "VIP" ? (
                      <span>2.799.000 UZS</span>
                    ) : selectedPlan === "Comfort" ? (
                      <span>499.000 UZS</span>
                    ) : null}
                  </>
                ) : null}
              </p>
              <Form.Item label="Izoh" name="izoh">
                <TextArea placeholder="Izoh qoldirish ixtiyoriy." allowClear />
              </Form.Item>
              <Form.Item valuePropName="checked">
                <Checkbox onChange={onChange}>
                  <a href="https://cdn.payme.uz/terms/main.html?target=_blank">
                    Ommaviy oferta va foydalanish shartlariga
                  </a>{" "}
                  roziman
                </Checkbox>
              </Form.Item>
              <Form.Item>
                <Button
                  disabled={!disable}
                  type="primary"
                  htmlType="submit"
                  block
                >
                  To'lov qilish
                </Button>
              </Form.Item>
              <p>
                Telefon raqamimiz:{" "}
                <a href="tel: +998777774672">+998777774672</a>{" "}
              </p>
              <img src="/paymePage/payme.png" alt="" />
            </div>
          </div>
        </div>
      </Form>
      <Modal
        title="To'lovingiz muofaqiyatli!"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleOk}
      >
        <p>Operatorlarimiz tez orada siz bilan bog'lanishadi.</p>
        <p>Yoki quidagi raqamga qong'roq qilishingiz mumkun</p>
        <p>
          <a href="tel:+998777774672">+998777774672</a>
        </p>
      </Modal>
    </div>
  );
};

export default PaymePage;
