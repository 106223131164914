import { Container } from 'react-bootstrap'
import { Collapse, Title } from '../../components/Elements'
import ShowCase from '../../components/ShowCase'

import WomenImg from '../../assets/women2.png'

import {BiChevronLeft} from 'react-icons/bi'

import './styles.scss'
import Feedback from '../../components/Feedback'
import ContactFrom from '../../components/ContactFrom'

const questions = [
    {question: 'Lorem, ipsum dolor sit amet consectetur adipisicing.', answer: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dignissimos sequi illum culpa corporis atque repellat sint consequuntur reiciendis, libero fugiat cupiditate, odit dolor, magni velit!'},
    {question: 'Lorem, ipsum dolor sit amet consectetur adipisicing.', answer: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dignissimos sequi illum culpa corporis atque repellat sint consequuntur reiciendis, libero fugiat cupiditate, odit dolor, magni velit!'},
    {question: 'Lorem, ipsum dolor sit amet consectetur adipisicing.', answer: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dignissimos sequi illum culpa corporis atque repellat sint consequuntur reiciendis, libero fugiat cupiditate, odit dolor, magni velit!'},
    {question: 'Lorem, ipsum dolor sit amet consectetur adipisicing.', answer: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dignissimos sequi illum culpa corporis atque repellat sint consequuntur reiciendis, libero fugiat cupiditate, odit dolor, magni velit!'},
    {question: 'Lorem, ipsum dolor sit amet consectetur adipisicing.', answer: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Dignissimos sequi illum culpa corporis atque repellat sint consequuntur reiciendis, libero fugiat cupiditate, odit dolor, magni velit!'},
]

export default function FAQ() {
  return (
    <div className='faq'>
        <ShowCase pagination='ABOUT US' height='70vh'>
            <Title line2='FAQ' textAlighCenter={true}/>
        </ShowCase>
        <Container>
            <Title 
                line1={'OUR FAQ'}
                line3={'Most Frequent Questions'}
                textAlighCenter={true}
            />
            <div className="questions">
                    {questions.map(({question, answer}, idx) => (
                        <div className="question" key={idx}>
                            <Collapse
                                toggle={
                                    <div className='toggle__wrapper'>
                                        <div className="toggle__text">{question}</div>
                                        <div className="toggle__button"><BiChevronLeft className='menu-collapse__icon'/></div>
                                    </div>
                                }
                            >
                                <>{answer}</>
                            </Collapse>
                        </div>
                    ))}
            </div>
        </Container>
        <Feedback />    
        <ContactFrom
            sideContent={<img src={WomenImg} alt="women" width='400' className='form-section__img' />}
            line1='SUBMIT A TICKET'
            line2='Not Found Answer Contact Us'
        />
    </div>
  )
}
