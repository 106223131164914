import './styles.scss'
import { Button, Title } from '../Elements';
import { Container } from 'react-bootstrap';

export default function ContactForm({sideContent, line1, line2}) {

  return (
    <section className="form-section">
      <Container>
        <div className="form-section_inner">
          <aside className='side-content'>
              {sideContent}
          </aside>
          <form className='form'>
              <Title line1={line1} line3={line2}/>
              <input type="text" placeholder='Full Name' className='name-input' />
              <input type="text" placeholder='Email Address' />
              <input type="text" placeholder='Phone No' />
              <textarea placeholder='Message'></textarea>
              <Button>SUBMIT NOW</Button>
          </form>
        </div>
      </Container>
    </section>
  )
}
