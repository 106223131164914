import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";

import { Outlet } from "react-router-dom";
import s from "./style.module.scss";
import Footer from "../Footer";
import { Link, NavLink } from "react-router-dom";
import { FaBars, FaTimes, FaTelegramPlane } from "react-icons/fa";
import { AiOutlineInstagram, AiFillYoutube } from "react-icons/ai";
import { GrFacebookOption } from "react-icons/gr";
import { TiWarning } from "react-icons/ti";
import Button from "../Elements/Button/Button";
import { Modal as AntdModal, Input, message } from "antd";
import Form from "antd/es/form/Form";
import axios from "axios";

const navLinks = [
  { text: "Home", url: "/" },
  { text: "Kurslar", url: "/courses" },
  { text: "Biz haqimizda", url: "/about" },
  // {text: 'Blogs', url: '/blogs'},
  // {text: 'FAQ', url: '/faq'},
  // {text: 'Pricing', url: '/pricing'},
  // { text: "Instruktorlar", url: "/instructor" },
  // { text: "Bog'lanish", url: "/contact" },
];

const Layout = () => {
  window.addEventListener("scroll", () => {
    const navbar = document.getElementById("navbar");
    if (navbar) {
      if (window.scrollY > 100) {
        navbar.style.background = "#fff";
        navbar.style.boxShadow =
          "rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px";
      } else {
        navbar.style.background = "transparent";
        navbar.style.boxShadow = "none";
      }
    }
  });
  const [gumbIsOpen, setGumbIsOpen] = useState(false);
  const gumb = useRef();
  const closeNavBtn = useRef();

  const path = useLocation();

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "instant" });
  }, [path]);

  const openNav = () => {
    if (gumbIsOpen) {
      gumb.current.style.transform = "translateX(-100%)";
      setGumbIsOpen(false);
    } else {
      gumb.current.style.transform = "translateX(0)";
      closeNavBtn.current.style.display = "block";
      setGumbIsOpen(true);
    }
  };
  const closeNav = () => {
    gumb.current.style.transform = "translateX(-100%)";
    closeNavBtn.current.style.display = "none";
    setGumbIsOpen(false);
  };

  // Modal
  const [formData] = Form.useForm();
  // const [loading, setLoading] = useState(false);
  // const handleOk = () => {};
  const [isModalOpen, setIsModelOpen] = useState(false);

  const handleChange = (e) => {
    const { value } = e.target;
    const formattedValue = formatPhoneNumber(value);
    formData.setFieldValue("phone", formattedValue);
  };

  const formatPhoneNumber = (value) => {
    // Удаляем все нецифровые символы из введенного значения
    const cleanedValue = value.replace(/\D/g, "");

    // Форматируем номер в требуемый формат
    let formattedValue = cleanedValue;
    if (cleanedValue.length > 2) {
      formattedValue = cleanedValue.replace(
        /(\d{2})(\d{3})(\d{2})(\d{2})/,
        "($1) $2-$3-$4"
      );
    } else if (cleanedValue.length > 0) {
      formattedValue = cleanedValue.replace(/(\d{1,2})/, "($1");
    }

    return formattedValue;
  };
  const cencelModal = () => {
    formData.resetFields();
    setIsModelOpen(false);
  };
  const openModal = () => {
    setIsModelOpen(true);
  };

  const handleSubmit = async (data) => {
    try {
      // send to server
      const resp = axios.post("https://myteacher.uz/api/register", {
        name: data.fullName,
        phone: `998${data.phone
          ?.replaceAll(" ", "")
          .replaceAll("-", "")
          .replace("(", "")
          .replace(")", "")}`,
        comment: data.message,
      });
      resp.then((e) => {
        console.log(e);
      });
    } catch (e) {
      // show notify
      message.error("Something went wrong!");
      console.log(e);
    } finally {
      setIsModelOpen(false);
    }
  };
  return (
    <div className={s.wrapper}>
      <div className={s.content}>
        <header className={s.home__header}>
          <div className={`${s.home__headerBox} g-container`}>
            <div className={s.home__links} style={{ color: "cyan" }}>
              {/* <a href="/#" >
                <HiLocationMarker style={{ color: "#ff5219" }} size={20} /> 12/7
                <span className={s.location}>Aabot, Poor Steet, Mumbai</span>
              </a>
              <a href="mailto: #">
                <BsFillEnvelopeFill size={20} style={{ color: "#ff5219" }} />
                info@gmail.com
              </a> */}
              <TiWarning spin={true} twoToneColor="red" color="red" size={'1.5rem'}/> Saytimiz TEST rejimda ishlayapti!
            </div>
            <div className={s.home__links}>
              <a href="https://www.facebook.com/myteacher.uz/">
                <GrFacebookOption style={{ fontSize: "20px" }} />
              </a>
              <a href="https://www.instagram.com/myteacher.uz/">
                <AiOutlineInstagram style={{ fontSize: "20px" }} />
              </a>
              <a
                href="https://t.me/Myteacher_IELTS"
                target="_blank"
                rel="noreferrer"
              >
                <FaTelegramPlane style={{ fontSize: "20px" }} />
              </a>
              <a href="https://www.youtube.com/@myteacheruz">
                <AiFillYoutube style={{ fontSize: "20px" }} />
              </a>
            </div>
          </div>
        </header>
        <div id="navbar" className={s.navbar}>
          <div className={`${s.navbar__box} g-container`}>
            <div className={s.navbar__logo}>
              <Link to="/">
                <img src="\about\logo\logo.svg" alt="" />
              </Link>
            </div>
            <ul className={s.navbar__list}>
              {navLinks.map(({ text, url }, idx) => (
                <li key={idx}>
                  <NavLink to={url}>{text}</NavLink>
                </li>
              ))}
              <li id="btn">
                <Button onClick={openModal} children="ARIZA QOLDIRING" />
              </li>
              <div className={s.navbar__menu}>
                <ul ref={gumb} id="gumb" type="none">
                  <li className={s.menu_header}>
                    <img src="\about\logo\logo.svg" alt="logo"  width="120" />
                    <FaTimes
                      className={s.menu_header_icon}
                      onClick={closeNav}
                    />
                  </li>
                  {navLinks.map(({ text, url }, idx) => (
                    <li key={idx} onClick={closeNav}>
                      <NavLink to={url}>{text}</NavLink>
                    </li>
                  ))}
                </ul>
                <div
                  className={s.open}
                  onClick={openNav}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <FaBars />
                </div>
                <div
                  onClick={closeNav}
                  ref={closeNavBtn}
                  className={s.close}
                ></div>
              </div>
            </ul>
          </div>
        </div>

        <div>
          <Outlet />
        </div>
      </div>
      <footer className={s.footer}>
        <Footer />
      </footer>

      {/* //Modal */}
      <AntdModal
        open={isModalOpen}
        footer={null}
        // closable={false}
        onCancel={() => {
          setIsModelOpen(false);
        }}
      >
        <p>
          <strong style={{ fontSize: "20px" }}>
            Alaykum assalom, MyTeacherga xush kelibsiz! Iltimos ismingiz va
            telefon raqamingizni qoldiring. Siz bilan tez orada bog'lanamiz.
          </strong>
        </p>
        <Form
          name="normal_login"
          className="login-form"
          onFinish={handleSubmit}
          form={formData}
        >
          <Form.Item
            key={"1"}
            name={"fullName"}
            label={"Ismingiz"}
            rules={[
              { required: true, message: "Iltimos ismingizni kiriting!" },
            ]}
          >
            <Input
              type={"text"}
              name={"fullName"}
              placeholder="Sizning ismingiz"
            />
          </Form.Item>
          <Form.Item
            key={"1"}
            name={"phone"}
            label={"Raqamingiz"}
            rules={[
              { required: true, message: "Iltimos raqmingizni kiriting!" },
            ]}
          >
            <Input
              prefix="+998"
              onChange={handleChange}
              maxLength={14}
              type={"tel"}
              name={"number"}
              placeholder="(__) ___ - __ - __"
            />
          </Form.Item>
          <Form.Item
            key={"1"}
            name={"message"}
            label={"Qo'shimcha"}
            // rules={[{ required: true, message: "Iltimos malumot kiriting!" }]}
          >
            <Input.TextArea
              rows={3}
              name={"Message"}
              placeholder="Izoh qoldirishingiz mumkin"
            />
          </Form.Item>
          <Form.Item valuePropName="checked">
            Ro'yxatdan o'tish bilan{" "}
            <a href="https://docs.google.com/document/d/1VZZqIF2vX_1D008AEvgHViZXZObIh7nNaPv0cSTTU0g/edit">
              <span style={{ color: "blue" }}>
                {" "}
                Ommaviy oferta va foydalanish shartlariga
              </span>
            </a>{" "}
            rozilik bildirasiz
          </Form.Item>
          <div className="flex items-center justify-end">
            <Button
              variant="secondary"
              onClick={cencelModal}
              size="sm"
              children="Yopish"
              style={{ margin: "0 10px" }}
            />
            <Button htmlType="submit" children="Jo'natish" size="sm" />
          </div>
        </Form>
      </AntdModal>
    </div>
  );
};

export default Layout;
