import React from "react";
import s from "./style.module.scss";
import { GiStabbedNote } from "react-icons/gi";
import { RiUserStarFill } from "react-icons/ri";
import { FaGraduationCap } from "react-icons/fa";

// line1 => 1-keladigan blue text
// line2 => faqat showcaseda keladigan katta main text
// underLine2 => line2 ni ichida keladigan ostida chizigi bor soz
// line3 => pageni kop joyida uchridigan katta text
// line4 => kichkina definition
// line3Center => line3 text-alighn: center bob kelsa true berish kerak

const index = ({
  line1 = null,
  line2 = null,
  underLine2 = null,
  line3 = null,
  imgResp = null,
  textAlighCenter = false,
  line4 = null,
}) => {
  return (
    <div className={s.title}>
      <h2 style={{ textAlign: textAlighCenter ? "center" : "start" }}>
        {line1}
      </h2>
      {line2 ? (
        <h1 style={{ textAlign: textAlighCenter ? "center" : "start" }}>
          {line2}{" "}
          <span className={s.line}>
            {underLine2} <img src="/title/line.png" alt="" />
          </span>
        </h1>
      ) : null}
      {line3 ? (
        <h3 style={{ textAlign: textAlighCenter ? "center" : "start" }}>
          {line3}
        </h3>
      ) : null}
      {imgResp ? (
        <div className={s.home__img}>
                        <div className={s.home__elemDiv}>
                <div className={s.home__elem}>
                  <div className={s.home__elemIcon}>
                    <GiStabbedNote color="#fff" size={15} />
                  </div>
                  <div className={s.home__elemTitle}>Meni tabriklang!</div>
                  <div className={s.home__elemText}>
                    Onlayn o'qib IELTS 8 oldim
                  </div>
                </div>
                <div className={s.home__elem}>
                  <div className={s.home__elemIcon}>
                    <RiUserStarFill color="#fff" size={15} />
                  </div>
                  <div className={s.home__elemTitle}>Native Speaker darsi</div>
                  <div className={s.home__elemText}>
                    O'rganish 2x tez bo'ladi
                  </div>
                </div>{" "}
                <div className={s.home__elem}>
                  <div className={s.home__elemIcon}>
                    <FaGraduationCap color="#fff" size={15} />
                  </div>
                  <div className={s.home__elemTitle}>300+</div>
                  <div className={s.home__elemText}>Mamnun mijozlar</div>
                </div>
              </div>
              <img className={s.title__respImg} src={imgResp} alt="img" />
        </div>
      ) : null}
      {line4 ? <p>{line4}</p> : null}
    </div>
  );
};

export default index;
