import './styles.scss'
import BGImg from '../../assets/1090x500.png'

import { Container, Row, Col } from 'react-bootstrap'
import { Title } from '../../components/Elements'
import InstructorBox from '../../components/IntructorBox'
import ShowCase from '../../components/ShowCase'

const instructors = [1,2,3,4];

export default function Instructor() {

  return (
    <div className='intructor'>
        <ShowCase pagination='ABOUT US' height='70vh'>
            <Title line2='FAQ' textAlighCenter={true}/>
        </ShowCase>
        <Container>
            <div className="banner">
                <img src={BGImg} alt="our team" className='banner__img' width='1090'/>
                <div className="banner__diteils">
                    <div className="title">Meet Our Instructor Team Members</div>
                    <div className="desc">If we had a 'secret sauce' it would be our awesome people. </div>
                </div>
            </div>
            <Title 
                line1='Best Coach'
                line3='Our Expert Instructor'
                textAlighCenter={true}
            />
            <div className="instructors">
                <Row>
                    {instructors.map((_, idx) => (
                        <Col lg={3} md={4} sm={6} key={idx}>
                            <InstructorBox />
                        </Col>
                    ))}
                </Row>
            </div>
        </Container>
    </div>
  )
}
