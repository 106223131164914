import {NavLink} from 'react-router-dom'
import './styles.scss'

import BoxImg from '../../assets/225x275.png'
import {GrFacebookOption, GrTwitter} from 'react-icons/gr'
import {IoLogoInstagram} from 'react-icons/io'

export default function IntructorBox() {
  return (
    <NavLink to='/instructor/diteils/f5fd8f5d2' className='instructor-box'>
        <div className="instructor-box__media">
            <img src={BoxImg} className="instructor-box__img" alt='instructor' />
            <div className="instructor-box__actions actions">
                <a href="https://facebook.com" className="actions__item">
                    <GrFacebookOption className='icon'/>
                </a>
                <a href="https://instagram.com" className="actions__item">
                    <IoLogoInstagram className='icon'/>
                </a>
                <a href="https://twitter.com" className="actions__item">
                    <GrTwitter className='icon'/>
                </a>
            </div>
        </div>
        <div className="instructor-box__body">
            <div className="full-name">Kelly Franklin</div>
            <div className="status">Instructor</div>
        </div>
    </NavLink>
  )
}
