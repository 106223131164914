import { Col, Container, Row } from 'react-bootstrap'
import { Title } from '../../components/Elements'
import ShowCase from '../../components/ShowCase'
import './styles.scss'

import AvatarImg from '../../assets/sm.blog.png'
import EventImg from '../../assets/1090x500.png'

export default function EventDiteil() {
  return (
    <div className='event-diteil'>
        <ShowCase pagination='ABOUT US' height='70vh'>
            <Title line2='FAQ' textAlighCenter={true}/>
        </ShowCase>
        <Container>
            <Row>
                <Col md={8}>
                    <Title line3='Business creativity workshops' />
                    <div className="event-diteil__info flex items-center">
                        <div className="info-item flex items-center">
                            <img src={AvatarImg} alt="avatar" className="event-diteil__info__avatar" width='60' height='60' />
                            <div>
                                <div className="full-name data">Lillian Wals</div>
                                <span className="status">Instructor</span>
                            </div>
                        </div>
                        <div className="info-item">
                            <div className="date data">30 Dec, 2021</div>
                            <span className="status">Event Date</span>
                        </div>
                        <div className="info-item">
                            <div className="location data">TX 82760, Poland</div>
                            <span className="status">Event Location</span>
                        </div>
                        <div className="info-item">
                            <div className="time data">10:30am To 2:30pm</div>
                            <span className="status">Event Time</span>
                        </div>
                    </div>
                    <img src={EventImg} alt="event" className="event-diteil__image" width='600' />
                    <div className="event-diteil__title">About this Event</div>
                    <div className="vent-diteil__desc">
                        Phasellus luctus ante eu nunc mollis, eget laoreet augue luctus. Proin tincidunt a sapien sed ultrices. Pellentesque faucibus quis augue id iaculis. Aliquam iaculis urna velit, a varius sem interdum et. Etiam eu molestie ipsum. Suspendisse vulputate elit eget volutpat pharetra. Maecenas eget iaculis leo. Suspendisse a tincidunt ante. Mauris semper odio ac felis aliquet, eu elementum lorem faucibus. Donec non tristique nunc. Maecenas tempus luctus arcu. Mauris quam lectus, viverra a tincidunt non, vehicula at metus. Ut mattis quam quis enim vehicula, quis ultricies urna finibus.
                    </div>
                </Col>
            </Row>
        </Container>
    </div>
  )
}
