import './styles.scss'
import PriceBox from '../../components/PriceBox'
import ShowCase from '../../components/ShowCase'
import Title from '../../components/Elements/Title'
import { Container, Row, Col } from 'react-bootstrap'
import Feedback from '../../components/Feedback'
import ContactFrom from '../../components/ContactFrom'
import WomenImg from '../../assets/women2.png'

const pracings = [1,2,3,]

export default function Pricings() {

  return (
    <div className='pricings-main'>
        <ShowCase pagination='ABOUT US' height='70vh'>
            <Title line2='FAQ' textAlighCenter={true}/>
        </ShowCase>
        <Container>
            <div className="pricings">
                <Row>
                    {pracings.map((_, idx) => (
                        <Col md={4} key={idx}>
                            <PriceBox />
                        </Col>
                    ))}
                </Row>
            </div>
        </Container>
        <Feedback />
        <ContactFrom
            sideContent={<img src={WomenImg} alt="women" width='400' className='form-section__img' />}
            line1='SUBMIT A TICKET'
            line2='Not Found Answer Contact Us'
        />
    </div>
  )
}
