import React from "react";
import { Title } from "../Elements";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import s from "./style.module.scss";
import "swiper/css/pagination";
import { Pagination } from "swiper";

const feedbacks = [
  {coment: "Thank you so much teacher for today's wonderful lesson. I realized you love your job because till midnight u worked with us honestly! appreciate your hard work. May Allah always support you Inshaallah I also try to be a hardworking teacher like you Good night teacher", location: 'Xorazm', name: 'Abdurakhimova Sh.'},
  {coment: "Rahmat. Roziman. Slani darslaringni zorligini isboti shu. Men erinchoq bulib atigi ikki marta reading qilib ozroq listening qilib borudim imtixonga. Shunchaki siladan taktika urganganim sababli task 1 yozolmagan busamam balim 6.0. Vashshe xayronman va xursandman. Daje tayyorlanmagan busam ham 6.5 ga ozgina yetmay qogan. Allohga shukur", location: 'Toshkent', name: 'Aliyeva U.'},
  {coment: "Assalamu aleykum yaxshimisiz hali endi 2chi kuni mazza qilib guruhingizdan dars qilayapman ishlaringizga omad ilohim martabangiz bundanda baland bòlsin. Suhandonligingizga kòz tegmasin 👏👏👏👏👏", location: 'Toshkent', name: 'Sultonova Sh.'},
]

const Feedback = () => {
  return (
    <div className={s.feedback}>
      <div className={`${s.feedback__box} container`}>
        <div className={s.feedback__tit}>
          <Title
            line1="MIJOZLARIMIZ FIKRI"
            line3="Mijozlarimiz nima deydi?"
            line4="MyTeacher mijozlari bizdan hamisha mamnun: bunga o'zingiz guvoh bo'ling va foydalanuvchilarimizning  biz haqimizdagi fikrlari bilan tanishing..."
          />
        </div>
        <div className={s.feedback__text}>
          <Swiper
            modules={[Pagination]}
            spaceBetween={50}
            slidesPerView={1}
            pagination={{ clickable: true }}
            autoplay={{
              delay: 5000,
            }}
          >
            {feedbacks.map((data, idx) => (
            <SwiperSlide key={idx}>
              <div className={s.feedback__notDots}>
                <p>
                  "{data.coment}".
                </p>
                <div>
                  <div className={s.feedback__ava}>
                    <div>
                      <div>
                        <img src="/homePage/avatar.png" alt="img" width='100' />
                      </div>
                    </div>
                    <section>
                      <h1>{data.name}</h1>
                      <p>{data.location}</p>
                    </section>
                  </div>
                  <img src="/homePage/quote-left.png" alt="" />
                </div>
              </div>
            </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default Feedback;
