import {useState} from 'react'
import './styles.scss'

export default function Collaspe({children, className = '', open = false, toggle, ...rest}) {

    const [isOpen, setIsOpen] = useState(open)

    const _toggle = () => setIsOpen(prev => !prev)
    const _className = `menu-collapse ${className} ${isOpen ?'expended':''}`
  return (
    <div className={_className} {...rest}>
        <div className="menu-collapse__toggle toggle" onClick={_toggle}>
          {toggle}
        </div>
        <div className="menu-collapse__body" onClick={() => setIsOpen(false)}>
          {children}
        </div>
    </div>
  )
}
