import { Col, Container, Row } from "react-bootstrap";
import { Button, Title } from "../../components/Elements";
import ShowCase from "../../components/ShowCase";
import "./styles.scss";

import AvatarImg from "../../assets/sm.blog.png";
import EventImg from "../../assets/1090x500.png";

export default function CourseDiteil({showCaseTitle, textTilte, avaImg, name, tags, courseImg, about, checks}) {
  return (
    <div className="course-diteil">
      <ShowCase pagination="COURSE" height="70vh">
        <Title line2={showCaseTitle} textAlighCenter={true} />
      </ShowCase>
      <div className="g-container">
        <Row>
          <Col md={8}>
            <Title line3={textTilte} />
            <div className="course-diteil__info flex items-center">
              <div className="info-item flex items-center">
                <img
                  src={avaImg}
                  alt="avatar"
                  className="course-diteil__info__avatar"
                  width="60"
                  height="60"
                />
                <div>
                  <div className="full-name data">{name}</div>
                  <span className="status">Instructor</span>
                </div>
              </div>
              <div className="info-item">
                <div className="date data">
                  <Button size="sm" children={tags.tag1} />
                </div>
              </div>
              <div className="info-item">
                <div className="location data">
                  <Button size="sm" variant="info" children={tags.tag2} />
                </div>
              </div>
            </div>
            <img
              src={courseImg}
              alt="img"
              className="course-diteil__image"
              width="600"
            />
            <div className="course-diteil__title">Kurs haqida</div>
            <div className="vent-diteil__desc">{about}</div>
          </Col>
          <Col md={4}>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Facere cum
            nam, fugit distinctio officiis cupiditate molestias, dolorem
            necessitatibus fuga sit pariatur alias atque a quod quaerat
            molestiae quia? Dolore, deleniti?
          </Col>
        </Row>
      </div>
    </div>
  );
}
